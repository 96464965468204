import { useDispatch } from 'react-redux'
import { loginGoogle } from '../../../actions/userActions'
import { GoogleLogin } from '@react-oauth/google'
import GoogleIcon from '../../layout/icons/social/GoogleIcon'
import jwt_decode from 'jwt-decode'


const GoogleLoginButton = ({ setLoginLoader, subscriber, isSignUp }) => {

    const dispatch = useDispatch()

    const googleLogin = async (res) => {          
        
        setLoginLoader(true)

        const decoded = jwt_decode(res?.credential)
        const token = res?.clientId   

        const profile = {
            decoded: decoded,
            token: token,
        }           
       
        try {
            dispatch(loginGoogle(profile, subscriber))           
        } catch (err) {
            console.log(err)
        }
    }

    return (

        <div className="relative"> 

            <button                                     
                type="button" 
                className="button google"
            >
                <GoogleIcon style={{ color: 'white' }} />
                Sign {isSignUp ? 'up' : 'in'} with Google
            </button> 

            <div 
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%', opacity: 0 }}
            >

                <GoogleLogin
                    onSuccess={googleLogin}
                    onError={() => {
                        console.log('Login Failed')
                    }}                                            
                    width="318"
                    // useOneTap="true"
                    text="signin_with"
                />    

            </div>  

        </div>   

    )

}

export default GoogleLoginButton