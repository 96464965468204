import DiscordIcon from '../../layout/icons/social/DiscordIcon'

const DiscordLoginButton = ({ setLoginLoader, subscriber, isSignUp }) => {

    const discordAuthUrl = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
        ? process.env.REACT_APP_DISCORD_LOGIN_URL
        : process.env.REACT_APP_DISCORD_LOGIN_URL_DEV

    const discordLogin = () => {
        setLoginLoader(true)
        window.location.href = `${discordAuthUrl}&state=${subscriber}`
    }

    return (

        <button 
            type="button" 
            className="button discord"
            onClick={discordLogin}
        >
            <DiscordIcon style={{ color: 'white' }} /> 
            Sign {isSignUp ? 'up' : 'in'} with Discord
        </button> 

    )

}

export default DiscordLoginButton