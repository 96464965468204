import './linearProgress.css'

const LinearProgress = () => {

    return (

        <div className="linear-progress" />

    )

}

export default LinearProgress