import KeyboardArrowLeftIcon from "../icons/arrows/KeyboardArrowLeftIcon"
import KeyboardArrowRightIcon from "../icons/arrows/KeyboardArrowRightIcon"
import KeyboardDoubleArrowLeftIcon from "../icons/arrows/KeyboardDoubleArrowLeftIcon"
import KeyboardDoubleArrowRightIcon from "../icons/arrows/KeyboardDoubleArrowRightIcon"

const TableControls = ({ resultsPerPage, handleResultsPerPageChange, currentPage, totalPages, handlePageChange }) => {

    return (

        <div 
          className="d-flex justify-content-between align-items-center" 
          style={{ gap: 20, margin: '20px 0' }}
        >

          <div>

            <small>Rows per page</small>  

            &nbsp;        

            <select 
                value={resultsPerPage} 
                onChange={handleResultsPerPageChange}
                style={{ 
                    border: 'solid 1px var(--border-color)', 
                    background: 'var(--background)',
                    color: 'var(--foreground)',
                    outline: 'none'
                }}
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={400}>All</option>
            </select>

          </div>

          <small>
            {currentPage} / {totalPages}
          </small>

          <div className="d-flex">

              <button className="icon-button" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                  <KeyboardDoubleArrowLeftIcon/>
              </button>
              &nbsp;
              <button className="icon-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  <KeyboardArrowLeftIcon />
              </button>        
              &nbsp;
              <button className="icon-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  <KeyboardArrowRightIcon />
              </button>
              &nbsp;
              <button className="icon-button" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                  <KeyboardDoubleArrowRightIcon />
              </button>

          </div>

        </div>

    )

}

export default TableControls