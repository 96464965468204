import {     
    ALL_SOCIALS_REQUEST,
    ALL_SOCIALS_SUCCESS,
    ALL_SOCIALS_FAIL,
    SOCIAL_DETAILS_REQUEST,
    SOCIAL_DETAILS_SUCCESS,
    SOCIAL_DETAILS_FAIL,    
    NEW_SOCIAL_REQUEST,
    NEW_SOCIAL_SUCCESS,
    NEW_SOCIAL_RESET,
    NEW_SOCIAL_FAIL,
    DELETE_SOCIAL_REQUEST,
    DELETE_SOCIAL_SUCCESS,
    DELETE_SOCIAL_RESET,
    DELETE_SOCIAL_FAIL,
    UPDATE_SOCIAL_REQUEST,
    UPDATE_SOCIAL_SUCCESS,
    UPDATE_SOCIAL_RESET,
    UPDATE_SOCIAL_FAIL,
    ALL_NUMERICS_REQUEST,
    ALL_NUMERICS_SUCCESS,
    ALL_NUMERICS_FAIL,
    NUMERIC_DETAILS_REQUEST,
    NUMERIC_DETAILS_SUCCESS,
    NUMERIC_DETAILS_FAIL,    
    NEW_NUMERIC_REQUEST,
    NEW_NUMERIC_SUCCESS,
    NEW_NUMERIC_RESET,
    NEW_NUMERIC_FAIL,
    DELETE_NUMERIC_REQUEST,
    DELETE_NUMERIC_SUCCESS,
    DELETE_NUMERIC_RESET,
    DELETE_NUMERIC_FAIL,
    UPDATE_NUMERIC_REQUEST,
    UPDATE_NUMERIC_SUCCESS,
    UPDATE_NUMERIC_RESET,
    UPDATE_NUMERIC_FAIL,
    ALL_GENERALS_REQUEST,
    ALL_GENERALS_SUCCESS,
    ALL_GENERALS_FAIL,
    GENERAL_DETAILS_REQUEST,
    GENERAL_DETAILS_SUCCESS,
    GENERAL_DETAILS_FAIL,    
    NEW_GENERAL_REQUEST,
    NEW_GENERAL_SUCCESS,
    NEW_GENERAL_RESET,
    NEW_GENERAL_FAIL,
    DELETE_GENERAL_REQUEST,
    DELETE_GENERAL_SUCCESS,
    DELETE_GENERAL_RESET,
    DELETE_GENERAL_FAIL,
    UPDATE_GENERAL_REQUEST,
    UPDATE_GENERAL_SUCCESS,
    UPDATE_GENERAL_RESET,
    UPDATE_GENERAL_FAIL,
    ALL_HOSTINGS_REQUEST,
    ALL_HOSTINGS_SUCCESS,
    ALL_HOSTINGS_FAIL,
    HOSTING_DETAILS_REQUEST,
    HOSTING_DETAILS_SUCCESS,
    HOSTING_DETAILS_FAIL,    
    NEW_HOSTING_REQUEST,
    NEW_HOSTING_SUCCESS,
    NEW_HOSTING_RESET,
    NEW_HOSTING_FAIL,
    DELETE_HOSTING_REQUEST,
    DELETE_HOSTING_SUCCESS,
    DELETE_HOSTING_RESET,
    DELETE_HOSTING_FAIL,
    UPDATE_HOSTING_REQUEST,
    UPDATE_HOSTING_SUCCESS,
    UPDATE_HOSTING_RESET,
    UPDATE_HOSTING_FAIL,
    ALL_STATS_REQUEST,
    ALL_STATS_SUCCESS,
    ALL_STATS_FAIL,
    STAT_DETAILS_REQUEST,
    STAT_DETAILS_SUCCESS,
    STAT_DETAILS_FAIL,    
    NEW_STAT_REQUEST,
    NEW_STAT_SUCCESS,
    NEW_STAT_RESET,
    NEW_STAT_FAIL,
    DELETE_STAT_REQUEST,
    DELETE_STAT_SUCCESS,
    DELETE_STAT_RESET,
    DELETE_STAT_FAIL,
    UPDATE_STAT_REQUEST,
    UPDATE_STAT_SUCCESS,
    UPDATE_STAT_RESET,
    UPDATE_STAT_FAIL,     
    ALL_EXCHANGES_REQUEST,
    ALL_EXCHANGES_SUCCESS,
    ALL_EXCHANGES_FAIL,
    EXCHANGE_DETAILS_REQUEST,
    EXCHANGE_DETAILS_SUCCESS,
    EXCHANGE_DETAILS_FAIL,    
    NEW_EXCHANGE_REQUEST,
    NEW_EXCHANGE_SUCCESS,
    NEW_EXCHANGE_RESET,
    NEW_EXCHANGE_FAIL,
    DELETE_EXCHANGE_REQUEST,
    DELETE_EXCHANGE_SUCCESS,
    DELETE_EXCHANGE_RESET,
    DELETE_EXCHANGE_FAIL,
    UPDATE_EXCHANGE_REQUEST,
    UPDATE_EXCHANGE_SUCCESS,
    UPDATE_EXCHANGE_RESET,
    UPDATE_EXCHANGE_FAIL, 
    ALL_STATES_REQUEST,
    ALL_STATES_SUCCESS,
    ALL_STATES_FAIL,
    STATE_DETAILS_REQUEST,
    STATE_DETAILS_SUCCESS,
    STATE_DETAILS_FAIL,    
    NEW_STATE_REQUEST,
    NEW_STATE_SUCCESS,
    NEW_STATE_RESET,
    NEW_STATE_FAIL,
    DELETE_STATE_REQUEST,
    DELETE_STATE_SUCCESS,
    DELETE_STATE_RESET,
    DELETE_STATE_FAIL,
    UPDATE_STATE_REQUEST,
    UPDATE_STATE_SUCCESS,
    UPDATE_STATE_RESET,
    UPDATE_STATE_FAIL, 
    CLEAR_ERRORS
} from '../constants/categoryConstants'

export const generalsReducer = ( state = { generals: [] }, action ) => {
    switch(action.type) {
        case ALL_GENERALS_REQUEST:
            return {                
                loading: true,
                generals: []
            }        
        case ALL_GENERALS_SUCCESS:
            return {
                loading: false,
                generals: action.payload.data               
            }                
        case ALL_GENERALS_FAIL:
             return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const generalDetailsReducer = ( state = { general: {} }, action ) => {
    switch(action.type) {
        case GENERAL_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case GENERAL_DETAILS_SUCCESS:
            return {
                loading: false,
                general: action.payload               
            }                
        case GENERAL_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newGeneralReducer = ( state = { general: {} }, action ) => {
    switch(action.type) {
        case NEW_GENERAL_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_GENERAL_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                general: action.payload.data               
            }                
        case NEW_GENERAL_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_GENERAL_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const generalReducer = ( state = {}, action ) => {
    switch(action.type) {
        case UPDATE_GENERAL_REQUEST:
        case DELETE_GENERAL_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_GENERAL_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_GENERAL_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_GENERAL_FAIL:
        case DELETE_GENERAL_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_GENERAL_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_GENERAL_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}

export const numericsReducer = ( state = { numerics: [] }, action ) => {
    switch(action.type) {
        case ALL_NUMERICS_REQUEST:
            return {                
                loading: true,
                numerics: []
            }        
        case ALL_NUMERICS_SUCCESS:
            return {
                loading: false,
                numerics: action.payload.data               
            }                
        case ALL_NUMERICS_FAIL:
             return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const numericDetailsReducer = ( state = { numeric: {} }, action ) => {
    switch(action.type) {
        case NUMERIC_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NUMERIC_DETAILS_SUCCESS:
            return {
                loading: false,
                numeric: action.payload               
            }                
        case NUMERIC_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newNumericReducer = ( state = { numeric: {} }, action ) => {
    switch(action.type) {
        case NEW_NUMERIC_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_NUMERIC_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                numeric: action.payload.data               
            }                
        case NEW_NUMERIC_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_NUMERIC_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const numericReducer = ( state = {}, action ) => {
    switch(action.type) {
        case UPDATE_NUMERIC_REQUEST:
        case DELETE_NUMERIC_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_NUMERIC_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_NUMERIC_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_NUMERIC_FAIL:
        case DELETE_NUMERIC_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_NUMERIC_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_NUMERIC_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}

export const socialsReducer = ( state = { socials: [] }, action ) => {
    switch(action.type) {
        case ALL_SOCIALS_REQUEST:
            return {                
                loading: true,
                socials: []
            }        
        case ALL_SOCIALS_SUCCESS:
            return {
                loading: false,
                socials: action.payload.data               
            }                
        case ALL_SOCIALS_FAIL:
             return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const socialDetailsReducer = ( state = { social: {} }, action ) => {
    switch(action.type) {
        case SOCIAL_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case SOCIAL_DETAILS_SUCCESS:
            return {
                loading: false,
                social: action.payload               
            }                
        case SOCIAL_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newSocialReducer = ( state = { social: {} }, action ) => {
    switch(action.type) {
        case NEW_SOCIAL_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_SOCIAL_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                social: action.payload.data               
            }                
        case NEW_SOCIAL_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_SOCIAL_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const socialReducer = ( state = {}, action ) => {
    switch(action.type) {
        case UPDATE_SOCIAL_REQUEST:
        case DELETE_SOCIAL_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_SOCIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_SOCIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_SOCIAL_FAIL:
        case DELETE_SOCIAL_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_SOCIAL_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_SOCIAL_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}

export const hostingsReducer = ( state = { hostings: [] }, action ) => {
    switch(action.type) {

        case ALL_HOSTINGS_REQUEST:
            return {
                loading: true,
                hostings: []
            }
        
        case ALL_HOSTINGS_SUCCESS:
            return {
                loading: false,
                hostings: action.payload.data               
            }
                
        case ALL_HOSTINGS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state
    }
}
export const hostingDetailsReducer = ( state = { hosting: {} }, action ) => {
    switch(action.type) {
        case HOSTING_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case HOSTING_DETAILS_SUCCESS:
            return {
                loading: false,
                hosting: action.payload               
            }                
        case HOSTING_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newHostingReducer = ( state = { hosting: {} }, action ) => {

    switch(action.type) {
        case NEW_HOSTING_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_HOSTING_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                hosting: action.payload.data               
            }                
        case NEW_HOSTING_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_HOSTING_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const hostingReducer = ( state = {}, action ) => {

    switch(action.type) {
        case UPDATE_HOSTING_REQUEST:
        case DELETE_HOSTING_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_HOSTING_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_HOSTING_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_HOSTING_FAIL:
        case DELETE_HOSTING_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_HOSTING_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_HOSTING_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}

export const statsReducer = ( state = { stats: [] }, action ) => {
    switch(action.type) {

        case ALL_STATS_REQUEST:
            return {
                loading: true,
                stats: []
            }
        
        case ALL_STATS_SUCCESS:
            return {
                loading: false,
                stats: action.payload.data               
            }
                
        case ALL_STATS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state
    }
}
export const statDetailsReducer = ( state = { stat: {} }, action ) => {
    switch(action.type) {
        case STAT_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case STAT_DETAILS_SUCCESS:
            return {
                loading: false,
                stat: action.payload               
            }                
        case STAT_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newStatReducer = ( state = { stat: {} }, action ) => {

    switch(action.type) {
        case NEW_STAT_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_STAT_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                stat: action.payload.data               
            }                
        case NEW_STAT_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_STAT_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const statReducer = ( state = {}, action ) => {

    switch(action.type) {
        case UPDATE_STAT_REQUEST:
        case DELETE_STAT_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_STAT_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_STAT_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_STAT_FAIL:
        case DELETE_STAT_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_STAT_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_STAT_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}

export const exchangesReducer = ( state = { exchanges: [] }, action ) => {
    switch(action.type) {

        case ALL_EXCHANGES_REQUEST:
            return {
                loading: true,
                exchanges: []
            }
        
        case ALL_EXCHANGES_SUCCESS:
            return {
                loading: false,
                exchanges: action.payload.data               
            }
                
        case ALL_EXCHANGES_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state
    }
}
export const exchangeDetailsReducer = ( state = { exchange: {} }, action ) => {
    switch(action.type) {
        case EXCHANGE_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case EXCHANGE_DETAILS_SUCCESS:
            return {
                loading: false,
                exchange: action.payload               
            }                
        case EXCHANGE_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newExchangeReducer = ( state = { exchange: {} }, action ) => {

    switch(action.type) {
        case NEW_EXCHANGE_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_EXCHANGE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                exchange: action.payload.data               
            }                
        case NEW_EXCHANGE_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_EXCHANGE_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const exchangeReducer = ( state = {}, action ) => {

    switch(action.type) {
        case UPDATE_EXCHANGE_REQUEST:
        case DELETE_EXCHANGE_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_EXCHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_EXCHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_EXCHANGE_FAIL:
        case DELETE_EXCHANGE_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_EXCHANGE_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_EXCHANGE_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}

export const statesReducer = ( state = { states: [] }, action ) => {
    switch(action.type) {

        case ALL_STATES_REQUEST:
            return {
                loading: true,
                states: []
            }
        
        case ALL_STATES_SUCCESS:
            return {
                loading: false,
                states: action.payload.data               
            }
                
        case ALL_STATES_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state
    }
}
export const stateDetailsReducer = ( state = { state: {} }, action ) => {
    switch(action.type) {
        case STATE_DETAILS_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case STATE_DETAILS_SUCCESS:
            return {
                loading: false,
                state: action.payload               
            }                
        case STATE_DETAILS_FAIL:
             return {
                 ...state,
                error: action.payload
            }       
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const newStateReducer = ( state = { state: {} }, action ) => {

    switch(action.type) {
        case NEW_STATE_REQUEST:
            return {     
                ...state,          
                loading: true                
            }        
        case NEW_STATE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                state: action.payload.data               
            }                
        case NEW_STATE_FAIL:
             return {
                 ...state,
                loading: false,
                error: action.payload
            }
        case NEW_STATE_RESET:
            return {     
                ...state,          
                success: false                
            } 
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}
export const stateReducer = ( state = {}, action ) => {

    switch(action.type) {
        case UPDATE_STATE_REQUEST:
        case DELETE_STATE_REQUEST:        
            return {     
                ...state,          
                loading: true                
            }        
        case DELETE_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload       
            } 
        case UPDATE_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload       
            }               
        case UPDATE_STATE_FAIL:
        case DELETE_STATE_FAIL:
             return {
                 ...state,
                error: action.payload
            }
        case DELETE_STATE_RESET:
            return {     
                ...state,          
                isDeleted: false                
            }
        case UPDATE_STATE_RESET:
            return {     
                ...state,          
                isUpdated: false                
            }  
        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }
        default:
            return state
    }
}