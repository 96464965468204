import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MetaData from './layout/MetaData'
import Modal from './modals/modal/Modal'
import Contact from './modals/Contact'
import ToolTip from './layout/tools/tooltip/ToolTip'
import MailOutlineIcon from './layout/icons/navigation/MailOutlineIcon'


const Landing = () => {

    const [ isModalVisible,  setIsModalVisible ] = useState(false)

    const { user, isAuthenticated } = useSelector( state => state.auth ) 
    
    const toggleModal = () => {
        setIsModalVisible(wasModalVisible => !wasModalVisible)
    }

    return (
        <>
        <MetaData title={'eYnodes Network'} description='Your One-Stop masternodes ressource' />
        
        <div className="container">
            
            <div className="wrapper">

                <div style={{
                    height: 'calc(100vh - 226px)',
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    caretColor: 'transparent'
                }}>   
                    <div className="text-center">       

                        <svg width="100" height="100">            
                            <path 
                                className="path draw" 
                                d="M45.965,66.72v7.016H15.989l-0.139,0.279c0.216,3.438,1.262,6.226,3.137,8.363s4.655,3.206,8.342,3.206
                                c3.036,0,5.616-0.31,7.738-0.929c4.634-1.353,6.792-2.983,6.792-2.983c3.021-1.774,13.737-8.066,13.737-8.066l-0.052-20.09
                                L31.708,5.007h14.848L63.5,41.211L80.551,5.007h14.848l-22.025,44.81c10.708-2.854,20.245-1.429,20.245-1.429l4.383,8.637
                                c0,0-12.981-2.326-26.461,2.539l0.131,6.451c11.481-3.577,21.947-1.979,21.947-1.979l4.383,8.637c0,0-12.846-2.049-26.365,2.948
                                l-0.072,15.45H55.543v-7.135l-14.776,9.001c0,0-1.156,0.578-3.633,1.381c-3.439,1.115-7.188,1.672-11.247,1.672
                                c-7.499,0-13.354-2.3-17.567-6.899C4.106,84.492,2,78.567,2,71.319v-1.858c0-7.527,2.045-13.69,6.135-18.492
                                c4.09-4.801,9.713-7.186,16.87-7.155c6.754,0,11.942,2.062,15.546,6.203C42.199,51.91,45.965,57.16,45.965,66.72z"
                            />
                            <path 
                                className="path" 
                                d="M45.965,66.72 M40.551,50.017 M30.861,56.8c1.208,1.688,1.813,4.003,1.813,6.946v1.022H16.128
                                c0,0-0.699-3.515,2.579-7.689c1.471-1.874,3.571-2.811,6.297-2.811C27.701,54.268,29.652,55.112,30.861,56.8z"
                            />
                        </svg>  

                        <br />

                        <h1>Coming soon ...</h1>    

                        {isAuthenticated  && user.role === 'admin' && (

                           <Link to="/home">
                                <button className="button">
                                    Home
                                </button>
                           </Link>

                        )}  

                        <br />

                        <ToolTip title="Contact">
                            <button 
                                className="icon-button"
                                aria-label="Contact" 
                                onClick={toggleModal}
                            >                                    
                                    <MailOutlineIcon />                                    
                            </button>  
                        </ToolTip>  

                    </div>    

                </div>                

            </div>

        </div>

        <Modal
            isModalVisible={isModalVisible} 
            onBackdropClick={toggleModal}   
            content={ <Contact /> }
        />

        </>
    )

}

export default Landing