const RemoveIcon = ({ style }) => {

    return (

        <svg style={style} className="icon" viewBox="0 0 24 24">
            <path d="M19 13H5v-2h14v2z" />
        </svg>

    )

}

export default RemoveIcon