const ArrowDropUpIcon = ({ style }) => {

  return (

    <svg style={style} className="icon" viewBox="0 0 24 24">
      <path d="m7 14 5-5 5 5z" />
    </svg>
    
  )

}

export default ArrowDropUpIcon