import { useEffect, useState } from 'react'
import DarkModeIcon from '../../icons/visibility/DarkModeIcon'
import LightModeIcon from '../../icons/visibility/LightModeIcon'
import ToolTip from '../tooltip/ToolTip'
import './modeToggle.css'

const ModeToggle = () => {    

    const [ darkMode, setDarkMode ] = useState(localStorage.getItem('darkMode'))

    useEffect(() => {
        let mode = localStorage.getItem('darkMode') 
        if (mode) {            
            setDarkMode(true)
            document.body.classList.add('darkmode')
        } else {
            setDarkMode(false)           
        }          
    }, [])

    const toggleMode = () => {        
        if (!darkMode) {
            setDarkMode(true)
            document.body.classList.add('darkmode')
            localStorage.setItem('darkMode', 'enabled')
        } else {
            setDarkMode(false)
            document.body.classList.remove('darkmode')
            localStorage.removeItem('darkMode')
        }       
    }

    return (        
        <ToolTip title={darkMode ? 'Light' : 'Dark'}>
            <button 
                className="icon-button" 
                aria-label="dark mode"
                onClick={toggleMode}
            >

                {darkMode
                ? <LightModeIcon />
                : <DarkModeIcon />}               

            </button>
        </ToolTip>
    )

}

export default ModeToggle