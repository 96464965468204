import './form.css'

const Radio = ({ onClick, checked, label, className }) => {    

    return (
        <>
        <div className="d-flex align-items-center gap-1">
            <span 
                className={`radio-button-container ${className}`}
                onClick={() => onClick(!checked)}
            >

                <span className={`radio-button ${checked ? 'checked' : ''}`} />

            </span> 
            <small>{label}</small>
        </div>        
        </>
    )

}

export default Radio