import { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { login, register, clearErrors } from '../../actions/userActions'
import { useNavigate, Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import Modal               from '../modals/modal/Modal'
import Loader              from '../layout/tools/loader/Loader'
import MetaData            from '../layout/MetaData'
import Input               from '../layout/forms/Input'
import KeyIcon             from '../layout/icons/login/KeyIcon'
import PersonAddAltIcon    from '../layout/icons/people/PersonAddAltIcon'
import LoginIcon           from '../layout/icons/login/LoginIcon'
import Eynodes             from '../layout/icons/navigation/Eynodes'
import Radio               from '../layout/forms/Radio'
// import TwitterLoginButton  from './buttons/TwitterLoginButton'
import GoogleLoginButton   from './buttons/GoogleLoginButton'
import GithubLoginButton   from './buttons/GithubLoginButton'
import DiscordLoginButton  from './buttons/DiscordLoginButton'
// import FacebookLoginButton from './buttons/FacebookLoginButton'

const Login = () => {
        
    const alert    = useAlert()
    const dispatch = useDispatch()
    const navigate = useNavigate()    

    const { loading, isAuthenticated, error } = useSelector( state => state.auth )  

    const [ name,           setName           ] = useState('')
    const [ email,          setEmail          ] = useState('')
    const [ password,       setPassword       ] = useState('')  
    const [ avatar,         setAvatar         ] = useState('')   
    const [ captcha,        setCaptcha        ] = useState(false)    
    const [ isSignUp,       setIsSignUp       ] = useState(false)    
    const [ subscriber,     setSubscriber     ] = useState(false)    
    const [ avatarPreview,  setAvatarPreview  ] = useState('/images/default-avatar.jpg')   
    const [ loginLoader,    setLoginLoader    ] = useState(false)  
    const [ shouldRedirect, setShouldRedirect ] = useState(false)    

    useEffect(() => {

        if (isAuthenticated) {
            setShouldRedirect(true)
        } else {
            setShouldRedirect(false)
        }

        if (error) { 
            alert.error(error)
            dispatch(clearErrors())
        }        
    }, [dispatch, navigate, alert, isAuthenticated, error, loading])

    useEffect(() => {

        if (shouldRedirect) {
            const redirectTimer = setTimeout(() => {
                navigate('/')
            }, 1000)

            return () => {
                clearTimeout(redirectTimer)
            }
        }
    }, [navigate, shouldRedirect])
   
    const submitHandler = (e) => {  
        e.preventDefault()     
        if (isSignUp) {
            setCaptcha(true)   
        } else {
            dispatch(login(name, password, subscriber))
        }        
    }   

    const handleChange = (value) => {  
        setCaptcha(false)
        const formData = new FormData()
        formData.set('name', name)
        formData.set('email', email)
        formData.set('password', password)
        formData.set('avatar'  , avatar)
        formData.set('subscriber', subscriber)
        formData.set('key', value)  
        dispatch(register(formData))
    }

    const onChange = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setAvatarPreview(reader.result)
                setAvatar(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])        
    }     
   
    return (        
        <>
        <MetaData title={'Login'} />        

        {loading || loginLoader || isAuthenticated ? <Loader /> : (                   
            
            <div className="container">

                <div className="wrapper">

                <form 
                        onSubmit={submitHandler} 
                        className="user-form login-form background-panel"
                        style={{ maxWidth: 800 }}
                    >

                        <div className="text-center">

                            <p style={{ marginBottom: 40 }}>
                            
                                {isSignUp 
                                    ? <PersonAddAltIcon style={{ fontSize: '2.5rem' }} />
                                    : <LoginIcon style={{ fontSize: '2.5rem' }} />
                                }  

                            </p>                              

                            <h1 >Choose a {isSignUp ? 'Sign Up ' : 'Sign in '} Method</h1>   

                        </div>
                        
                        <div className="parent gap-4 relative column-reverse" >

                            <div>  

                               <DiscordLoginButton 
                                    setLoginLoader={setLoginLoader} 
                                    subscriber={subscriber} 
                                    isSignUp={isSignUp}
                               />

                                <GithubLoginButton 
                                    setLoginLoader={setLoginLoader} 
                                    subscriber={subscriber} 
                                    isSignUp={isSignUp}
                                />

                                <GoogleLoginButton 
                                    setLoginLoader={setLoginLoader} 
                                    subscriber={subscriber} 
                                    isSignUp={isSignUp}
                                />

                                {/* <TwitterLoginButton 
                                    setLoginLoader={setLoginLoader} 
                                    subscriber={subscriber} 
                                    isSignUp={isSignUp}
                                />     */}

                                {/* <FacebookLoginButton
                                    setLoginLoader={setLoginLoader} 
                                    subscriber={subscriber} 
                                    isSignUp={isSignUp}
                                />                     */}

                            </div>

                            <hr className={`divider ${isSignUp ? 'sign-up' : 'sign-in'}`} />

                            <div>

                                <div>

                                    <div className="d-flex gap-2" style={{ marginBottom: 20 }}>

                                        {isSignUp && (
                                            <label>                            
                                                <input
                                                    type="file"  
                                                    style={{ display: 'none' }}
                                                    name="avatar"                            
                                                    onChange={onChange}                                                                                                                             
                                                />
                                                <img 
                                                    src={avatarPreview} 
                                                    alt="Avatar Preview" 
                                                    style={{ width: '59px', height: '59px', borderRadius: '50%' }}
                                                />                              
                                            </label>                                        
                                        )}                                                                     

                                        <Input 
                                            placeholder="Name" 
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            style={{ height: 64, padding: '20px 10px' }}
                                            autoFocus
                                        />  

                                    </div>

                                    {isSignUp && ( 
                                        <div style={{ marginBottom: 20 }}> 
                                            <Input 
                                                placeholder="Email" 
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                style={{ height: 64, padding: '20px 10px' }}                                            
                                            />  
                                        </div> 
                                    )} 

                                    <Input 
                                        type="password"
                                        placeholder="Password"
                                        value={password}                             
                                        onChange={(e) => setPassword(e.target.value)} 
                                        style={{ height: 64, padding: '20px 10px' }}
                                    />  

                                    <button 
                                        type="submit" 
                                        className="button"
                                        style={{ justifyContent: 'flex-start', height: 64, margin: '20px 0', textTransform: 'none' }}
                                        disabled={
                                            isSignUp 
                                                ? !name || !email || !password ? true : false
                                                : !name || !password ? true : false
                                        }
                                    >
                                        <Eynodes style={{ color: 'white' }} />
                                        &nbsp;&nbsp;
                                        {isSignUp ? 'Sign up' : 'Sign In'} with {process.env.REACT_APP_SITE_NAME}
                                    </button>                           

                                    <div className="d-flex justify-content-between align-items-center whitespace-nowrap gap-2">                                             
                                        <Link 
                                            to="/password/forgot"
                                            className="d-flex align-items-center"
                                            style={{ gap: 10 }}
                                        >
                                            <KeyIcon /> <small>Forgot password?</small>
                                        </Link> 
                                        <div 
                                            className="d-flex align-items-center cursor-pointer link"
                                            style={{ gap: 10 }}
                                            onClick={() => setIsSignUp(!isSignUp)}
                                        >
                                            {isSignUp ? (
                                                <>
                                                <LoginIcon /> <small>Sign In</small>
                                                </>
                                            ) : (
                                                <>
<                                               PersonAddAltIcon /> <small>Sign Up</small>
                                                </>
                                            )}
                                            
                                        </div>  
                                    </div>    

                                </div>  

                            </div>
                            
                        </div>                           

                        <div style={{ marginTop: 20 }} className="text-center">
                            <small>Subsribe for our <b>free News letter</b> and stay up to date</small> 

                            <Radio 
                                onClick={setSubscriber} 
                                checked={subscriber} 
                            />   
                        </div>    

                    </form>

                </div>

            </div>                           

        )} 
        <Modal
            isModalVisible={captcha} 
            onBackdropClick={() => setCaptcha(false)}   
            content={ 
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleChange}
                /> 
            }
        />   
        </>
    )

}

export default Login