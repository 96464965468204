const ArrowRightIcon = ({ style }) => {

    return (

        <svg style={style} className="icon" viewBox="0 0 24 24">
            <path d="m10 17 5-5-5-5v10z" />
        </svg>
        
    )
}

export default ArrowRightIcon