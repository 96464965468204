export const NEW_PAGE_REQUEST = 'NEW_PAGE_REQUEST'
export const NEW_PAGE_SUCCESS = 'NEW_PAGE_SUCCESS'
export const NEW_PAGE_RESET = 'NEW_PAGE_RESET'
export const NEW_PAGE_FAIL = 'NEW_PAGE_FAIL'

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST'
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_RESET = 'UPDATE_PAGE_RESET'
export const UPDATE_PAGE_FAIL = 'UPDATE_PAGE_FAIL'

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST'
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS'
export const DELETE_PAGE_RESET = 'DELETE_PAGE_RESET'
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL'

export const PAGE_DETAILS_REQUEST = 'PAGE_DETAILS_REQUEST'
export const PAGE_DETAILS_SUCCESS = 'PAGE_DETAILS_SUCCESS'
export const PAGE_DETAILS_FAIL = 'PAGE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
