import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../../../actions/userActions'
import ToolTip                from '../tools/tooltip/ToolTip'
import Backdrop               from '../tools/Backdrop'
import ModeToggle             from '../tools/modeToggle/ModeToggle'
import SearchIcon             from '../icons/navigation/SearchIcon'
import Eynodes                from '../icons/navigation/Eynodes'
import SpeedIcon              from '../icons/navigation/SpeedIcon'
import CategoryIcon           from '../icons/navigation/CategoryIcon'
import AddIcon                from '../icons/actions/AddIcon'
import ArrowDropUpIcon        from '../icons/arrows/ArrowDropUpIcon'
import ArrowDropDownIcon      from '../icons/arrows/ArrowDropDownIcon'
import LogoutIcon             from '../icons/login/LogoutIcon'
import PeopleAltIcon          from '../icons/people/PeopleAltIcon'
import GroupsIcon             from '../icons/people/GroupsIcon'
import StorageIcon            from '../icons/categories/StorageIcon'
import LeaderboardIcon        from '../icons/categories/LeaderboardIcon'
import CurrencyExchangeIcon   from '../icons/categories/CurrencyExchangeIcon'
import InfoIcon               from '../icons/categories/InfoIcon'
import FormatListNumberedIcon from '../icons/categories/FormatListNumberedIcon'
import LoginIcon              from '../icons/login/LoginIcon'
import ToggleOnIcon           from '../icons/categories/ToggleOnIcon'
import DescriptionIcon        from '../icons/navigation/DescriptionIcon'
import StarIcon               from '../icons/navigation/StarIcon'
import './header.css'

const Header = () => {  

    const alert    = useAlert()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isAuthenticated } = useSelector( state => state.auth )   

    const [ isProductVisible,  setProductVisible  ] = useState(false)
    const [ isCategoryVisible, setCategoryVisible ] = useState(false)
    const [ isPageVisible, setPageVisible ] = useState(false)
    
    const productMenuAppear = useSpring({
        transform: isProductVisible ? "translateY(0)" : "translateY(-40px)",
        opacity: isProductVisible ? 1 : 0
    })  

    const pageMenuAppear = useSpring({
        transform: isPageVisible ? "translateY(0)" : "translateY(-40px)",
        opacity: isPageVisible ? 1 : 0
    })  

    const categoryMenuAppear = useSpring({
        transform: isCategoryVisible ? "translateY(0)" : "translateY(-40px)",
        opacity: isCategoryVisible ? 1 : 0
    }) 
    
    const toggleMenu = (setFunction) => {
        setFunction(value => !value)
    }

    const closeMenus = () => {
        setProductVisible(false)
        setCategoryVisible(false)
        setPageVisible(false)
    }
    
    const logoutHandler = () => {
        dispatch(logout())
        alert.success('Logged out')
        navigate('/auth')
    }  

    return (
        <>  
        <header className="background-panel d-flex align-items-center justify-content-between">                

            <Link to="/" className="logo">
                <img                    
                    src={`/images/logo.png`}
                    alt={`${process.env.REACT_APP_SITE_NAME} logo`}
                    width="40"
                    height="49"                                                             
                />                
            </Link>                    

            <nav style={{ marginRight: 10 }}> 
                                
                    <div 
                        className="d-flex align-items-center flex-wrap" 
                        style={{ gap: 4 }}
                    >     
                        <ModeToggle /> 

                        {isAuthenticated ? (  
                            <> 
                            {user.role === 'admin' && (
                                <>
                                <ToolTip title="Dashboard">
                                    <Link to="/admin/dashboard">
                                        <button className="icon-button">
                                            <SpeedIcon />
                                        </button>
                                    </Link>
                                </ToolTip> 

                                <ToolTip title="Update Banners">
                                    <Link to="/admin/banners">
                                        <button className="icon-button">
                                            <StarIcon />
                                        </button>
                                    </Link>
                                </ToolTip>                             

                                <span 
                                    onClick={() => {toggleMenu(setCategoryVisible)}} 
                                    className="relative d-flex align-items-center"
                                >                        
                            
                                    <ToolTip title="Categories">
                                        <button className="icon-button">                                    
                                            <CategoryIcon />  
                                        </button>
                                    </ToolTip>

                                    {isCategoryVisible ? (
                                        <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} />
                                    ):(
                                        <ArrowDropDownIcon  style={{ color: 'var(--cta-green)' }} />
                                    )}                                
                                
                                    {isCategoryVisible && ( 
                                        <>
                                        <Backdrop
                                            onClick={closeMenus}
                                            invisible
                                        /> 
                                        <animated.div className="dropdown-menu" style={categoryMenuAppear}>  
                                            <ul>                            
                                                <li>
                                                    <Link to="/admin/generals">
                                                        <small>General</small> <InfoIcon />                                         
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/admin/numerics">
                                                        <small>Numeric</small> <FormatListNumberedIcon /> 
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/admin/socials">
                                                        <small>Social</small> <GroupsIcon /> 
                                                    </Link>
                                                </li>
                                    
                                                <li>
                                                    <Link to="/admin/hostings">
                                                        <small>Hosting</small> <StorageIcon />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/admin/stats">
                                                        <small>Stats</small> <LeaderboardIcon />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/admin/exchanges">
                                                        <small>Exchanges</small> <CurrencyExchangeIcon />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/admin/states">
                                                        <small>States</small> <ToggleOnIcon />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </animated.div>
                                        </>
                                    )}
                                </span> 

                                <span 
                                    onClick={() => {toggleMenu(setProductVisible)}} 
                                    className="relative d-flex align-items-center"
                                >                        
                            
                                    <ToolTip title="Nodes">
                                        <button className="icon-button">                                    
                                            <Eynodes />  
                                        </button>
                                    </ToolTip>

                                    {isProductVisible ? (
                                        <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} />
                                    ):(
                                        <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                                    )}                                
                                
                                    {isProductVisible && ( 
                                        <>
                                        <Backdrop
                                            onClick={closeMenus}
                                            invisible
                                        /> 
                                        <animated.div className="dropdown-menu" style={productMenuAppear}>  
                                            <ul>                            
                                                <li>
                                                    <Link to="/admin/products">
                                                        <small>All nodes</small> <SearchIcon />
                                                    </Link>
                                                </li>
                                    
                                                <li>
                                                    <Link to="/admin/product">
                                                        <small>New node</small> <AddIcon style={{ color: 'var(--cta-green)' }} />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </animated.div>
                                        </>
                                    )}
                                </span> 

                                <span 
                                    onClick={() => {toggleMenu(setPageVisible)}} 
                                    className="relative d-flex align-items-center"
                                >                        
                            
                                    <ToolTip title="Pages">
                                        <button className="icon-button">                                    
                                            <DescriptionIcon />  
                                        </button>
                                    </ToolTip>

                                    {isPageVisible ? (
                                        <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} />
                                    ):(
                                        <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                                    )}                                
                                
                                    {isPageVisible && ( 
                                        <>
                                        <Backdrop
                                            onClick={closeMenus}
                                            invisible
                                        /> 
                                        <animated.div className="dropdown-menu" style={pageMenuAppear}>  
                                            <ul>                            
                                                <li>
                                                    <Link to="/admin/pages">
                                                        <small>All pages</small> <SearchIcon />
                                                    </Link>
                                                </li>
                                    
                                                <li>
                                                    <Link to="/admin/page">
                                                        <small>New page</small> <AddIcon style={{ color: 'var(--cta-green)' }} />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </animated.div>
                                        </>
                                    )}
                                </span> 

                                <ToolTip title="Users">
                                    <Link to="/admin/users">
                                        <button className="icon-button">
                                            <PeopleAltIcon />
                                        </button>
                                    </Link>
                                </ToolTip>                            
                                </>
                            )}  

                            <ToolTip title={`${user.name} (${user.role})`}>                            
                                <Link to="/me">
                                <img 
                                    alt={user && user.name} 
                                    src={user.avatar ? user.avatar.url : '/images/default-avatar.jpg'}  
                                    width="48"
                                    style={{ borderRadius: '50%' }}
                                /> 
                                </Link>
                            </ToolTip>  
                            
                            <ToolTip title="Logout" right>
                                <button className="icon-button" onClick={logoutHandler} aria-label="logout">
                                    <LogoutIcon />
                                </button>
                            </ToolTip>  
                            </>
                        ) : (
                            <ToolTip title="Login">
                                <Link to="/auth" aria-label="login">
                                    <button className="icon-button" aria-label="login">
                                        <LoginIcon />
                                    </button>
                                </Link>
                            </ToolTip>    
                        )}
                    </div>                
            </nav>                   
       
        </header>  
        </>
    )

}

export default Header