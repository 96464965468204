import React, { useState } from 'react'
import './tooltip.css'

const ToolTip = ({ title, children, style, right }) => {

  const [ isTooltipVisible, setIsTooltipVisible ] = useState(true)

  const handleMouseEnter = () => {
    setIsTooltipVisible(true)
  }

  const handleMouseLeave = () => {
    setIsTooltipVisible(false)
  }

  return (
    <div
      style={style}
      className="tooltip-container" 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      {isTooltipVisible && 
        <div 
          className="tooltip text-center"
          style={{ 
            right: right ? 0 : 'auto', 
            left: right ? 'auto' : '50%',
            transform: right ? 'none' : 'translateX(-50%)' 
          }}
        >
          {title}
        </div>
      }
    </div>
  )
}

export default ToolTip
