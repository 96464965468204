export const checkUrl = (value) => {        
    let urlRegex = /^https?:\/\//    
    return urlRegex.test(value) ? true : false
}

export const sanitizeInput = (value) => {
    value = value.replace(/[^\w -]/ig, '')
    value = value.replace(/ /ig, '-')
    return value.toLowerCase()  
}

export const urlencodeFormData = ( formData ) => {
    const params = new URLSearchParams()
    for( let pair of formData.entries() ) {
        typeof pair[1]=='string' && params.append( pair[0], pair[1] )
    }
    return params.toString()
} 

export const formatMyDate = (dateStr) => {
    const d = new Date(dateStr)
    const day     = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
    const month   = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    const year    = d.getFullYear()
    return `${day}/ ${month}/ ${year}`
}

export const formatMyDateTime = (dateStr) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const date = new Date(dateStr)
    const dayOfWeek = daysOfWeek[date.getDay()]
    const month = monthsOfYear[date.getMonth()]
    const day = date.getDate()
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const suffix = day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th'

    const formattedDate = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${dayOfWeek} ${day}${suffix} ${month}, ${year}`
    return formattedDate
}

export const formatMyPrice = (x, curr) => {
    
    let y
     
    if (curr) y = '$'

    const parts = x.toString().split('.')
    const wholeNumberPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    
    y += wholeNumberPart
    
    if (parts.length > 1) {
        y += '.' + parts[1]
    }

    return y
} 