import { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import RefreshIcon       from '../icons/actions/RefreshIcon'
import Input             from '../forms/Input'
import ToolTip           from '../tools/tooltip/ToolTip'
import VisibilityIcon    from '../icons/visibility/VisibilityIcon'
import VisibilityOffIcon from '../icons/visibility/VisibilityOffIcon'
import LeaderboardIcon   from '../icons/categories/LeaderboardIcon'
import Checkbox from '../forms/Checkbox'
import Radio from '../forms/Radio'

const Filtering = ({ 
    data, 
    setCurrentPage, 
    setFilteredData,     
    filteredData,
    searchTerm, 
    setSearchTerm,
    minPrice, setMinPrice, maxPrice, setMaxPrice,
    minAge, setMinAge, maxAge, setMaxAge,
    minVol, setMinVol, maxVol, setMaxVol,
    minCap, setMinCap, maxCap, setMaxCap,
    minRoi, setMinRoi, maxRoi, setMaxRoi,
    minNodes, setMinNodes, maxNodes, setMaxNodes,
    minRqmn, setMinRqmn, maxRqmn, setMaxRqmn,
    minValmn, setMinValmn, maxValmn, setMaxValmn,
    active,
    setActive,
    maintenance,
    setMaintenance,
    graveyard,
    setGraveyard,
    tiered,
    setTiered,
    monitored,
    setMonitored,
    migrated,
    setMigrated,
    qtWallet,
    setQtWallet,
    handleReset,
    all,
    setAll
}) => {
    
    const [ showFilter, setShowFilter ] = useState(false)    

    const appear = useSpring({
        transform: showFilter ? 'translateY(0)' : 'translateY(-40px)',
        opacity: showFilter ? 1 : 0
    }) 

    const filterData = (
        _active,
        _maintenance,
        _graveyard,
        _migrated,
        _tiered,
        _monitored,        
        _qtWallet,
        min, max, 
        _minAge,   _maxAge, 
        _minVol,   _maxVol, 
        _minCap,   _maxCap,  
        _minRoi,   _maxRoi,        
        _minNodes, _maxNodes,         
        _minRqmn,  _maxRqmn, 
        _minValmn, _maxValmn, 
        searchTerm        
    ) => {
        const filteredResults = data.filter((item) => {

            const priceInRange =
                (!min || item.xlsFeed.find((i) => i.name === 'PRICE')?.value >= min) &&
                (!max || item.xlsFeed.find((i) => i.name === 'PRICE')?.value <= max)   
                
            const ageInRange =
                (!_minAge || item.xlsFeed.find((i) => i.name === 'AGE')?.value >= _minAge) &&
                (!_maxAge || item.xlsFeed.find((i) => i.name === 'AGE')?.value <= _maxAge)

            const volInRange =
                (!_minVol || item.xlsFeed.find((i) => i.name === 'VOL')?.value >= _minVol) &&
                (!_maxVol || item.xlsFeed.find((i) => i.name === 'VOL')?.value <= _maxVol)

            const capInRange =
                (!_minCap || item.xlsFeed.find((i) => i.name === 'CAP')?.value >= _minCap) &&
                (!_maxCap || item.xlsFeed.find((i) => i.name === 'CAP')?.value <= _maxCap)

            const roiInRange =
                (!_minRoi || item.xlsFeed.find((i) => i.name === 'ROI')?.value >= _minRoi) &&
                (!_maxRoi || item.xlsFeed.find((i) => i.name === 'ROI')?.value <= _maxRoi)

            const nodesInRange =
                (!_minNodes || item.xlsFeed.find((i) => i.name === 'NODES')?.value >= _minNodes) &&
                (!_maxNodes || item.xlsFeed.find((i) => i.name === 'NODES')?.value <= _maxNodes)           

            const rqmnInRange =
                (!_minRqmn || item.xlsFeed.find((i) => i.name === 'RQMN')?.value >= _minRqmn) &&
                (!_maxRqmn || item.xlsFeed.find((i) => i.name === 'RQMN')?.value <= _maxRqmn)

            const valmnInRange =
                (!_minValmn || item.xlsFeed.find((i) => i.name === 'VALMN')?.value >= _minValmn) &&
                (!_maxValmn || item.xlsFeed.find((i) => i.name === 'VALMN')?.value <= _maxValmn)
    
            const isActiveItem = _active 
                ? item.states[0]?.setting
                : !item.states[0]?.setting

            const isMaintenanceItem = _maintenance 
                ? item.states[1]?.setting
                : !item.states[1]?.setting   

            const isGraveyardItem = _graveyard 
                ? item.states[2]?.setting
                : !item.states[2]?.setting  

            const isMigratedItem = _migrated 
                ? item.states[3]?.setting
                : !item.states[3]?.setting  

            const isMonitoredItem = _monitored 
                ? item.states[4]?.setting
                : !item.states[4]?.setting  

            const isTieredItem = _tiered 
                ? item.states[5]?.setting
                : !item.states[5]?.setting              

            const isQtWalletItem = _qtWallet 
                ? item.states[6]?.setting
                : !item.states[6]?.setting  

            if (
                (isActiveItem && _active) 
                || (isMaintenanceItem && _maintenance)
                || (isGraveyardItem && _graveyard)
                || (isMigratedItem && _migrated)                
                || (isMonitoredItem && _monitored)
                || (isTieredItem && _tiered)
                || (isQtWalletItem && _qtWallet)
                || searchTerm
            ) {
                if (searchTerm) {
                    return (
                        Object.values(item).some((value) =>
                            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                        ) 
                            && priceInRange 
                            && ageInRange 
                            && volInRange 
                            && capInRange 
                            && roiInRange 
                            && nodesInRange 
                            && rqmnInRange 
                            && valmnInRange                                                    
                    )
                } else {
                    return (
                        priceInRange 
                        && ageInRange 
                        && volInRange 
                        && capInRange 
                        && roiInRange 
                        && nodesInRange 
                        && rqmnInRange 
                        && valmnInRange 
                        && (isActiveItem
                            || (isActiveItem && isMonitoredItem) 
                            || (isActiveItem && isTieredItem) 
                            || (isActiveItem && isQtWalletItem)                             
                            || isMaintenanceItem                             
                            || isGraveyardItem                             
                            || isMigratedItem                            
                            )                        
                    )
                }
            } else return null            
            
        })  
        setFilteredData(filteredResults)
        setCurrentPage(1)
    }  

    const handleMinPrice = (e) => {
        setMinPrice(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, e.target.value, maxPrice)
    }

    const handleMaxPrice = (e) => {
        setMaxPrice(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, e.target.value)
    }

    const handleMinAge = (e) => {
        setMinAge(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, e.target.value, maxAge)
    }

    const handleMaxAge = (e) => {
        setMaxAge(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, e.target.value)
    }

    const handleMinVol = (e) => {
        setMinVol(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, e.target.value, maxVol)
    }

    const handleMaxVol = (e) => {
        setMaxVol(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, e.target.value)
    }

    const handleMinCap = (e) => {
        setMinCap(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, e.target.value, maxCap)
    }

    const handleMaxCap = (e) => {
        setMaxCap(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, e.target.value)
    } 
    
    const handleMinRoi = (e) => {
        setMinRoi(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, e.target.value, maxRoi)
    }

    const handleMaxRoi = (e) => {
        setMaxRoi(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, e.target.value)
    } 

    const handleMinNodes = (e) => {
        setMinNodes(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, e.target.value, maxNodes)
    }

    const handleMaxNodes = (e) => {
        setMaxNodes(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, e.target.value)
    }

    const handleMinRqmn = (e) => {
        setMinRqmn(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, e.target.value, maxRqmn)
    }

    const handleMaxRqmn = (e) => {
        setMaxRqmn(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, e.target.value)
    }

    const handleMinValmn = (e) => {
        setMinValmn(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, e.target.value, maxValmn)
    }

    const handleMaxValmn = (e) => {
        setMaxValmn(e.target.value)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, e.target.value)
    }

    const resetAll = (active, maintenance, graveyard, migrated, tiered, monitored, qtWallet) => {
        if (active && maintenance && graveyard && migrated && tiered && monitored && qtWallet) {
            setAll(true)
        } else {
            setAll(false)
        }
    }

    const handleActive = () => { 
        resetAll(!active, maintenance, graveyard, migrated, tiered, monitored, qtWallet)   
        setActive(!active)        
        filterData(!active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }

    const handleMaintenance = () => { 
        resetAll(active, !maintenance, graveyard, migrated, tiered, monitored, qtWallet)       
        setMaintenance(!maintenance)        
        filterData(active, !maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }

    const handleGraveyard = () => {
        resetAll(active, maintenance, !graveyard, migrated, tiered, monitored, qtWallet)
        setGraveyard(!graveyard)
        filterData(active, maintenance, !graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }

    const handleMigrated = () => {
        resetAll(active, maintenance, graveyard, !migrated, tiered, monitored, qtWallet)
        setMigrated(!migrated)
        filterData(active, maintenance, graveyard, !migrated, tiered, monitored, qtWallet,  minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }

    const handleTiered = () => {
        resetAll(active, maintenance, graveyard, migrated, !tiered, monitored, qtWallet)
        setTiered(!tiered)
        filterData(active, maintenance, graveyard, migrated, !tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }

    const handleMonitored = () => {
        resetAll(active, maintenance, graveyard, migrated, tiered, !monitored, qtWallet)
        setMonitored(!monitored)
        filterData(active, maintenance, graveyard, migrated, tiered, !monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }    

    const handleQtWallet = () => {
        resetAll(active, maintenance, graveyard, migrated, tiered, monitored, !qtWallet)
        setQtWallet(!qtWallet)
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, !qtWallet,  minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
    }

    const handleAll = () => {
        if (all) {
            setAll(false)
            setActive(false)
            setMaintenance(false)
            setGraveyard(false)
            setMigrated(false)
            setTiered(false)
            setMonitored(false)            
            setQtWallet(false)
            filterData(false, false, false, false, false, false, false,  minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
        } else {
            setAll(true)
            setActive(true)
            setMaintenance(true)
            setGraveyard(true)
            setMigrated(true)
            setTiered(true)
            setMonitored(true)            
            setQtWallet(true)
            filterData(true, true, true, true, true, true, true,  minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn)
        }        
    }

    // const resetFilters = () => {
    //     setMinPrice('')
    //     setMaxPrice('')
    //     setMinAge('')
    //     setMaxAge('')        
    //     setMinVol('')
    //     setMaxVol('')
    //     setMinCap('')
    //     setMaxCap('')
    //     setMinRoi('')
    //     setMaxRoi('')
    //     setMinNodes('')
    //     setMaxNodes('')
    //     setMinRqmn('')
    //     setMaxRqmn('')
    //     setMinValmn('')
    //     setMaxValmn('')
    //     setSearchTerm('')
    //     setActive(true)
    //     setMaintenance(true)
    //     setGraveyard(true)
    //     setMigrated(true)
    //     setTiered(true)
    //     setMonitored(true)        
    //     setQtWallet(true)
    //     setAll(true)
    //     filterData(true, true, true, true, true, true, true)
    // }

    const handleSearchChange = (e) => {  
        setSearchTerm(e.target.value)        
        if (e.target.value.length < 3) return  
        filterData(active, maintenance, graveyard, migrated, tiered, monitored, qtWallet, minPrice, maxPrice, minAge, maxAge, minVol, maxVol, minCap, maxCap, minRoi, maxRoi, minNodes, maxNodes, minRqmn, maxRqmn, minValmn, maxValmn, e.target.value)
    }

    const handleFilters = () => {
        setShowFilter(!showFilter)
    }

    return (
        <div style={{ padding: 20, background: 'var(--background)' }}>
        
            <Input 
                placeholder="Node search..." 
                value={searchTerm} 
                onChange={handleSearchChange} 
                style={{ background: 'var(--card-background)' }}                
            />  

            <div className="parent align-items-center gap-2">          

                <button 
                    className="button relative" 
                    style={{ width: 200, padding: 10, marginTop: 20, justifyContent: 'space-between' }}
                    onClick={handleFilters}    
                >
                    {showFilter ? 'Hide' : 'Show'} Filters
                    {showFilter 
                        ? <VisibilityIcon style={{ color: 'inherit' }} /> 
                        : <VisibilityOffIcon style={{ color: 'inherit' }} />
                    }
                </button>

                <p className="d-flex gap-1">
                    <b style={{ fontSize: '18px' }}>
                    {filteredData.length} result{filteredData.length !== 1 ? 's' : ''}
                    </b>
                    <LeaderboardIcon />
                </p>

            </div>

            {showFilter && (
                <animated.div style={appear}>     

                    <div className="d-flex gap-2 flex-wrap">               

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>Price</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minPrice}
                                    onChange={handleMinPrice}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxPrice}
                                    onChange={handleMaxPrice}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div>         

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>Age</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minAge}
                                    onChange={handleMinAge}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxAge}
                                    onChange={handleMaxAge}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div>

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>Vol</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minVol}
                                    onChange={handleMinVol}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxVol}
                                    onChange={handleMaxVol}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div>

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>Cap</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minCap}
                                    onChange={handleMinCap}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxCap}
                                    onChange={handleMaxCap}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div>   

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>Roi</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minRoi}
                                    onChange={handleMinRoi}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxRoi}
                                    onChange={handleMaxRoi}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div> 

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>Nodes</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minNodes}
                                    onChange={handleMinNodes}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxNodes}
                                    onChange={handleMaxNodes}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div>  

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>RQMN</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minRqmn}
                                    onChange={handleMinRqmn}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxRqmn}
                                    onChange={handleMaxRqmn}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div> 

                        <div 
                            className="d-flex direction-column gap-2"
                            style={{ flexGrow: 1, marginTop: 20 }}
                        >

                            <small><b>VALMN</b></small>

                            <div>

                                <Input 
                                    type="number"
                                    placeholder="min:"
                                    value={minValmn}
                                    onChange={handleMinValmn}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                                <br />

                                <Input
                                    type="number"
                                    placeholder="max:"
                                    value={maxValmn}
                                    onChange={handleMaxValmn}
                                    style={{ background: 'var(--card-background)' }}   
                                />

                            </div>

                        </div> 

                    </div>   

                    <div 
                        className="d-flex justify-content-center" 
                        style={{ marginTop: 20 }}                         
                    >
                        <span 
                            className="d-flex align-items-center gap-1 cursor-pointer" 
                            style={{ width: 100 }}
                            onClick={handleFilters}   
                        >
                            <ToolTip title="Hide">
                                <VisibilityOffIcon style={{ color: 'var(--icon-color)', fontSize: '1rem' }} />
                            </ToolTip>
                            <small style={{ color: '#999' }}>Hide filters</small>
                        </span>
                    </div>                 

                </animated.div>
            )}  

            <div 
                className="d-flex justify-content-between"
                style={{ padding: '20px 0' }}
            >
                <Checkbox 
                    label={'All states & attributes'} 
                    checked={all} 
                    onChange={handleAll} 
                />

                <span className="d-flex align-items-center gap-1">
                    <small>Reset</small>
                    <ToolTip title="Reset">
                        <button    
                            className="icon-button"                     
                            onClick={handleReset}
                        >
                            <RefreshIcon />
                        </button>
                    </ToolTip>
                </span>
            </div>   

            <h6 style={{ fontSize: '14px' }}>States</h6>        

            <div 
                className="d-flex gap-2 flex-wrap"
                style={{ padding: '0 0 10px 0', marginLeft: -10 }}
            >                

                <Radio 
                    label={'Active'} 
                    checked={active} 
                    onClick={handleActive} 
                    className="active-state"
                />

                <Radio 
                    label={'Maintenance'} 
                    checked={maintenance} 
                    onClick={handleMaintenance} 
                    className="maintenance-state"
                />

                <Radio 
                    label={'Graveyard'} 
                    checked={graveyard} 
                    onClick={handleGraveyard} 
                    className="graveyard-state"
                />

                <Radio 
                    label={'Migrated'} 
                    checked={migrated} 
                    onClick={handleMigrated} 
                    className="migrated-state"
                />               

            </div>

            <h6 style={{ fontSize: '14px' }}>Attributes</h6>

            <div 
                className="d-flex gap-2 flex-wrap"
                style={{ padding: '10px 0 0 0' }}
            >                

                <Checkbox 
                    label={'Monitored'} 
                    checked={monitored} 
                    onChange={handleMonitored} 
                />

                <Checkbox 
                    label={'Tiered'} 
                    checked={tiered} 
                    onChange={handleTiered} 
                />

                <Checkbox 
                    label={'QT Wallet'} 
                    checked={qtWallet} 
                    onChange={handleQtWallet} 
                />

            </div>            

        </div>   
    )

}

export default Filtering