import { Fragment, useState  } from 'react'
import { useSpring, animated } from 'react-spring'
import { formatMyPrice       } from '../../../Utils'
import ArrowDropUpIcon         from '../icons/arrows/ArrowDropUpIcon'
import ArrowDropDownIcon       from '../icons/arrows/ArrowDropDownIcon'
import ToolTip                 from '../tools/tooltip/ToolTip'
import HiddenRow               from './HiddenRow'
import Filtering               from './Filtering'
import TableControls           from './TableControls'
import './table.css'

const DataTable = ({ data }) => {

  const [ currentPage,       setCurrentPage       ] = useState(1)
  const [ resultsPerPage,    setResultsPerPage    ] = useState(50)
  const [ filteredData,      setFilteredData      ] = useState(data)
  const [ sortConfig,        setSortConfig        ] = useState(null)
  const [ openRows,          setOpenRows          ] = useState([])
  const [ nodeIndex,         setNodeIndex         ] = useState(0)
  const [ searchTerm,        setSearchTerm        ] = useState('')
  const [ minPrice,          setMinPrice          ] = useState('')
  const [ maxPrice,          setMaxPrice          ] = useState('')
  const [ minAge,            setMinAge            ] = useState('')
  const [ maxAge,            setMaxAge            ] = useState('')
  const [ minVol,            setMinVol            ] = useState('')
  const [ maxVol,            setMaxVol            ] = useState('')
  const [ minCap,            setMinCap            ] = useState('')
  const [ maxCap,            setMaxCap            ] = useState('')
  const [ minRoi,            setMinRoi            ] = useState('')
  const [ maxRoi,            setMaxRoi            ] = useState('')
  const [ minNodes,          setMinNodes          ] = useState('')
  const [ maxNodes,          setMaxNodes          ] = useState('')
  const [ minRqmn,           setMinRqmn           ] = useState('')
  const [ maxRqmn,           setMaxRqmn           ] = useState('')
  const [ minValmn,          setMinValmn          ] = useState('')
  const [ maxValmn,          setMaxValmn          ] = useState('')
  const [ highlightedColumn, setHighlightedColumn ] = useState(null)
  const [ active,            setActive            ] = useState(true)
  const [ maintenance,       setMaintenance       ] = useState(true)
  const [ graveyard,         setGraveyard         ] = useState(true)
  const [ tiered,            setTiered            ] = useState(true)
  const [ monitored,         setMonitored         ] = useState(true)
  const [ migrated,          setMigrated          ] = useState(true)
  const [ qtWallet,          setQtWallet          ] = useState(true)
  const [ all,               setAll               ] = useState(true)

  const appear = useSpring({
    transform: openRows[nodeIndex] ? "translateY(0)" : "translateY(-40px)",
    opacity: openRows[nodeIndex] ? 1 : 0
  }) 

  const handleSort = (columnName) => {  
    let direction = 'desc'
    if (sortConfig && sortConfig.column === columnName && sortConfig.direction === 'desc') {
      direction = 'asc'
    }
    setSortConfig({ column: columnName, direction })
  }

  const sortedData = () => {
    if (!sortConfig) {
      return filteredData
    }

    const { column, direction } = sortConfig

    return [...filteredData].sort((a, b) => {
      
      if (column === 'ticker') {
        if (a[column] < b[column]) {
          return direction === 'asc' ? -1 : 1
        }
        if (a[column] > b[column]) {
          return direction === 'asc' ? 1 : -1
        }
        return 0
      }

      const aValue = a.xlsFeed.find(item => item.name === column)
      const bValue = b.xlsFeed.find(item => item.name === column)

      if (aValue && bValue) {
        if (aValue.value < bValue.value) {
          return direction === 'asc' ? -1 : 1
        }
        if (aValue.value > bValue.value) {
          return direction === 'asc' ? 1 : -1
        }
        return 0
      }

      return 0
      })

  }

  const handleResultsPerPageChange = (event) => {
    const newResultsPerPage = parseInt(event.target.value)
    setResultsPerPage(newResultsPerPage)
    setCurrentPage(1)
  }

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(filteredData.length / resultsPerPage)) {
      setCurrentPage(newPage)
    }
  } 

  const totalPages = Math.ceil(filteredData.length / resultsPerPage)

  const currentResults = sortedData().slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  )

  const handleRowClick = (rowId, index) => {
    let oldArray = [...openRows]
    let newArray = [] 
    newArray[index] =  oldArray[index] === rowId ? null : rowId
    setNodeIndex(index)
    setOpenRows(newArray)    
  }  

  const handleReset = () => {
    setCurrentPage(1)
    setFilteredData(data)
    setSortConfig(null)
    setOpenRows([])
    setNodeIndex(0)
    setSearchTerm('')
    setMinPrice('')
    setMaxPrice('')
    setMinAge('')
    setMaxAge('')
    setMinVol('')
    setMaxVol('')
    setMinCap('')
    setMaxCap('')
    setMinRoi('')
    setMaxRoi('')
    setMinNodes('')
    setMaxNodes('')  
    setMinRqmn('')
    setMaxRqmn('')   
    setMinValmn('')
    setMaxValmn('')  
    setActive(true)
    setMaintenance(true)
    setGraveyard(true)
    setTiered(true)
    setMonitored(true)
    setMigrated(true)
    setQtWallet(true)
    setAll(true)
  }  

  const handleMouseEnter = (columnIndex) => {    
    setHighlightedColumn(columnIndex)
  }

  const handleMouseLeave = () => {
    setHighlightedColumn(null)
  }  

  return (
    <>  
    <Filtering 
      data={data}
      setCurrentPage={setCurrentPage}
      filteredData={filteredData} setFilteredData={setFilteredData}   
      searchTerm={searchTerm} setSearchTerm={setSearchTerm}
      minPrice={minPrice} setMinPrice={setMinPrice} maxPrice={maxPrice} setMaxPrice={setMaxPrice}
      minAge={minAge} setMinAge={setMinAge} maxAge={maxAge} setMaxAge={setMaxAge}
      minVol={minVol} setMinVol={setMinVol} maxVol={maxVol} setMaxVol={setMaxVol}
      minCap={minCap} setMinCap={setMinCap} maxCap={maxCap} setMaxCap={setMaxCap}
      minRoi={minRoi} setMinRoi={setMinRoi} maxRoi={maxRoi} setMaxRoi={setMaxRoi}
      minNodes={minNodes} setMinNodes={setMinNodes} maxNodes={maxNodes} setMaxNodes={setMaxNodes}
      minRqmn={minRqmn} setMinRqmn={setMinRqmn} maxRqmn={maxRqmn} setMaxRqmn={setMaxRqmn}
      minValmn={minValmn} setMinValmn={setMinValmn} maxValmn={maxValmn} setMaxValmn={setMaxValmn}
      active={active} setActive={setActive}
      maintenance={maintenance} setMaintenance={setMaintenance}
      graveyard={graveyard} setGraveyard={setGraveyard}
      migrated={migrated} setMigrated={setMigrated}
      tiered={tiered} setTiered={setTiered}
      monitored={monitored} setMonitored={setMonitored}      
      qtWallet={qtWallet} setQtWallet={setQtWallet}
      handleReset={handleReset}
      all={all} setAll={setAll}
    />

    <div className="data-table-container">     

      {filteredData.length > 0 ? (
        <>    
        <TableControls 
          resultsPerPage={resultsPerPage} 
          handleResultsPerPageChange={handleResultsPerPageChange} 
          currentPage={currentPage} 
          totalPages={totalPages} 
          handlePageChange={handlePageChange}
        /> 

        <div className="data-table-wrapper">
        
          <table className="data-table">
              <thead>
              <tr>
                  <th 
                    onClick={handleReset} 
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                    className="text-center cursor-pointer"
                  >
                    <ToolTip title="Reset">
                      <img src="/images/logo.png" alt="avatar" />
                    </ToolTip>
                  </th>

                  <th 
                      onClick={() => handleSort('ticker')} 
                      onMouseEnter={() => handleMouseEnter(1)}
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex align-items-center">
                      TICKER 
                      {sortConfig?.column === 'ticker' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      }  
                    </div>
                  </th>

                  {/* {currentResults[0] && currentResults[0].xlsFeed.map((item, itemIndex) => (
                  
                    <th 
                        key={itemIndex}
                        onClick={() => handleSort(item.name)} 
                        onMouseEnter={() => handleMouseEnter(itemIndex + 2)}
                        onMouseLeave={handleMouseLeave}
                    >
                      <div className="d-flex align-items-center">
                        {item.name} 
                        {sortConfig?.column === item.name 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      } 
                        {sortConfig?.column === item.name && (sortConfig.direction === 'asc' ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />)}
                      </div>
                    </th>
                  ))} */}

                  <th
                    onClick={() => handleSort('PRICE')}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      PRICE 
                      {sortConfig?.column === 'PRICE' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      }  
                    </div>
                  </th>

                  <th
                    onClick={() => handleSort('AGE')}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      AGE 
                      {sortConfig?.column === 'AGE' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      }  
                    </div>
                  </th>

                  <th
                    onClick={() => handleSort('VOL')}
                    onMouseEnter={() => handleMouseEnter(4)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      VOL 
                      {sortConfig?.column === 'VOL' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      } 
                    </div>
                  </th>                  

                  <th
                    onClick={() => handleSort('CAP')}
                    onMouseEnter={() => handleMouseEnter(5)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      CAP 
                      {sortConfig?.column === 'CAP' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      } 
                    </div>
                  </th>

                  <th
                    onClick={() => handleSort('ROI')}
                    onMouseEnter={() => handleMouseEnter(6)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      ROI 
                      {sortConfig?.column === 'ROI' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      } 
                    </div>
                  </th>

                  <th
                    onClick={() => handleSort('NODES')}
                    onMouseEnter={() => handleMouseEnter(7)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      NODES 
                      {sortConfig?.column === 'NODES' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      }  
                    </div>
                  </th>
                  
                  <th
                    onClick={() => handleSort('RQMN')}
                    onMouseEnter={() => handleMouseEnter(8)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      RQMN 
                      {sortConfig?.column === 'RQMN' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      }  
                    </div>
                  </th>                 

                  <th
                    onClick={() => handleSort('VALMN')}
                    onMouseEnter={() => handleMouseEnter(9)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center">
                      VALMN 
                      {sortConfig?.column === 'VALMN' 
                          ? sortConfig.direction === 'asc' 
                              ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} /> 
                              : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                          : <ArrowDropUpIcon style={{ color: '#999' }} />
                      }  
                    </div>
                  </th>

              </tr>
              </thead>
              <tbody>
              {currentResults.map((product, index) => (
                  
                  <Fragment key={product._id}>
                      <tr 
                          onClick={() => handleRowClick(product._id, index)}
                          className={`
                            cursor-pointer table-row 
                            ${openRows.includes(product._id) ? 'open' : ''}
                            ${product.states[0].setting ? 'active-state' : ''}
                            ${product.states[1].setting ? 'maintenance-state' : ''}
                            ${product.states[2].setting ? 'graveyard-state' : ''}
                            ${product.states[3].setting ? 'migrated-state' : ''}
                            ${product.states[4].setting ? 'monitored-state' : ''}
                          `}
                      >
                          <td className={highlightedColumn === 0 ? 'highlighted-column' : ''}>
                              <ToolTip title={product.name} style={{ display: 'block' }}>
                                <img
                                    src={product.icon ? product.icon.url : '/images/default-avatar.png'} 
                                    alt={product.name} 
                                    style={{ width: 24, height: 24 }}                                
                                />
                              </ToolTip>
                          </td>

                          <td className={highlightedColumn === 1 ? 'highlighted-column' : ''}>{product.ticker}</td>

                          {product.xlsFeed.map((xlsFeedItem, numIndex) => (
                            
                            <Fragment key={numIndex}>                              

                              {xlsFeedItem.name === 'PRICE' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && `$${xlsFeedItem.value.toFixed(8)}`}
                                </td>
                              )}

                              {xlsFeedItem.name === 'AGE' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && xlsFeedItem.value}
                                  {xlsFeedItem.value && 'm'}
                              </td>
                              )}

                              {xlsFeedItem.name === 'VOL' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && formatMyPrice(xlsFeedItem.value, true)}
                                </td>
                              )}

                              {xlsFeedItem.name === 'CAP' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && formatMyPrice(xlsFeedItem.value, true)}
                                </td>
                              )}

                              {xlsFeedItem.name === 'ROI' && (
                                  <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                    {xlsFeedItem.value && (xlsFeedItem.value * 100).toFixed(2)}
                                    {xlsFeedItem.value && '%'}
                                  </td>
                              )}

                              {xlsFeedItem.name === 'NODES' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && xlsFeedItem.value}
                                </td>
                              )}

                              {xlsFeedItem.name === 'RQMN' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && xlsFeedItem.value}
                                </td>
                              )}

                              {xlsFeedItem.name === 'VALMN' && (
                                <td className={highlightedColumn === numIndex + 2 ? 'highlighted-column' : ''}>
                                  {xlsFeedItem.value && formatMyPrice(xlsFeedItem.value, true)}
                                </td>
                              )}

                            </Fragment>

                          ))}                       
                        
                      </tr>
                      {openRows.includes(product._id) && ( 
                        <animated.tr style={appear} className="hidden-row">
                          <HiddenRow product={product} />
                        </animated.tr>           
                      )}
                  </Fragment>
              ))}
              </tbody>
          </table>

        </div>   

        <TableControls 
          resultsPerPage={resultsPerPage} 
          handleResultsPerPageChange={handleResultsPerPageChange} 
          currentPage={currentPage} 
          totalPages={totalPages} 
          handlePageChange={handlePageChange}
        />  
        </>
      ) : (

        <p style={{ margin: '40px 0 0 20px' }}><small>No results...</small></p>

      )}
      
    </div>
    </>
  )
}

export default DataTable
