import RemoveIcon from '../icons/actions/RemoveIcon'
import ArrowRightIcon from '../icons/arrows/ArrowRightIcon'

const LinkButton = ({ array, category }) => {

    const checkUrl = (array, category) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].name === category) {
                if (array[i].url) {
                    return array[i].url
                }
            }
        }
    }

    return (        
        <>   
        {checkUrl(array, category) 
            ? (
                <div className="d-flex align-items-center">

                    <ArrowRightIcon style={{ color: 'var(--cta-green)' }} />       
                    
                    <a href={checkUrl(array, category)} target="_blank" rel="noreferrer">
                        {category}
                    </a>

                </div>
            ) 
            : (
                <div className="d-flex align-items-center">

                    <RemoveIcon style={{ color: 'var(--cta-red)' }} /> 
                    
                    <s>{category}</s>
                    
                </div>   
            )}
        </>  
    )
}

export default LinkButton