import { useState } from 'react'
import VisibilityOffIcon from '../icons/visibility/VisibilityOffIcon'
import VisibilityIcon from '../icons/visibility/VisibilityIcon'
import './form.css'

const Input = ({ type, name, placeholder, value, onChange, style, autoFocus, readonly, rows }) => {

    const [ passwordVisible, setPasswordVisible ] = useState()

    const togglePassword = (e) => {
        e.preventDefault() 
        setPasswordVisible(!passwordVisible)
    } 

    return (
        <>
        {(!type || type === 'text') && (
            <label className="label">

                <input
                    name={name}
                    className="input"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    style={style}
                    readOnly={readonly}
                    autoFocus={autoFocus}
                />

                <small
                    // style={{ 
                    //     opacity: value ? 1 : 0,
                    //     top: value ? -16 : 10,
                    //     left: value ? 0 : 10 
                    // }}
                >
                    {placeholder}
                </small>

            </label>
        )}        

        {type === 'number' && (
            <label className="label">

                <input
                    name={name}
                    className="input"
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    style={style}
                    readOnly={readonly}
                />

                <small>{placeholder}</small>

            </label>
        )}        

        {type === 'password' && (
            <label className="label">                           

                <input 
                    name={name}
                    className="input"
                    type={passwordVisible ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}                    
                    placeholder={placeholder}
                    style={style}
                />

                <small>Password</small>

                <span className="adornment-end">

                    <button 
                        className="icon-button"
                        onClick={togglePassword}
                        aria-label="visibility toggle"
                    >
                        {passwordVisible 
                            ? <VisibilityIcon /> 
                            : <VisibilityOffIcon />                            
                        }
                    </button>

                </span>

            </label> 
        )}

        {type==="textarea" && (
            <label className="label">  

                <textarea 
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="input"
                    placeholder={placeholder}
                    style={style}
                    rows={rows}
                />            

                <small>{placeholder}</small>

            </label>
        )}
        </>
    )

}

export default Input