export const ADMIN_PRODUCTS_REQUEST = 'ADMIN_PRODUCTS_REQUEST'
export const ADMIN_PRODUCTS_SUCCESS = 'ADMIN_PRODUCTS_SUCCESS'
export const ADMIN_PRODUCTS_FAIL = 'ADMIN_PRODUCTS_FAIL'

export const CATEGORY_PRODUCTS_REQUEST = 'CATEGORY_PRODUCTS_REQUEST'
export const CATEGORY_PRODUCTS_SUCCESS = 'CATEGORY_PRODUCTS_SUCCESS'
export const CATEGORY_PRODUCTS_FAIL = 'CATEGORY_PRODUCTS_FAIL'

export const ALL_PRODUCTS_REQUEST = 'ALL_PRODUCTS_REQUEST'
export const ALL_PRODUCTS_SUCCESS = 'ALL_PRODUCTS_SUCCESS'
export const ALL_PRODUCTS_FAIL = 'ALL_PRODUCTS_FAIL'

export const ALL_BOOKING_PRODUCTS_REQUEST = 'ALL_BOOKING_PRODUCTS_REQUEST'
export const ALL_BOOKING_PRODUCTS_SUCCESS = 'ALL_BOOKING_PRODUCTS_SUCCESS'
export const ALL_BOOKING_PRODUCTS_FAIL = 'ALL_BOOKING_PRODUCTS_FAIL'

export const RANDOM_PRODUCTS_REQUEST = 'RANDOM_PRODUCTS_REQUEST'
export const RANDOM_PRODUCTS_SUCCESS = 'RANDOM_PRODUCTS_SUCCESS'
export const RANDOM_PRODUCTS_FAIL = 'RANDOM_PRODUCTS_FAIL'

export const NEW_PRODUCT_REQUEST = 'NEW_PRODUCT_REQUEST'
export const NEW_PRODUCT_SUCCESS = 'NEW_PRODUCT_SUCCESS'
export const NEW_PRODUCT_RESET = 'NEW_PRODUCT_RESET'
export const NEW_PRODUCT_FAIL = 'NEW_PRODUCT_FAIL'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_RESET = 'UPDATE_PRODUCT_RESET'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'

export const UPDATE_PRODUCTS_REQUEST = 'UPDATE_PRODUCTS_REQUEST'
export const UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS'
export const UPDATE_PRODUCTS_RESET = 'UPDATE_PRODUCTS_RESET'
export const UPDATE_PRODUCTS_FAIL = 'UPDATE_PRODUCTS_FAIL'

export const UPDATE_PRODUCT_STATS_REQUEST = 'UPDATE_PRODUCT_STATS_REQUEST'
export const UPDATE_PRODUCT_STATS_SUCCESS = 'UPDATE_PRODUCT_STATS_SUCCESS'
export const UPDATE_PRODUCT_STATS_RESET = 'UPDATE_PRODUCT_STATS_RESET'
export const UPDATE_PRODUCT_STATS_FAIL = 'UPDATE_PRODUCT_STATS_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const LATEST_PRODUCT_DETAILS_REQUEST = 'LATEST_PRODUCT_DETAILS_REQUEST'
export const LATEST_PRODUCT_DETAILS_SUCCESS = 'LATEST_PRODUCT_DETAILS_SUCCESS'
export const LATEST_PRODUCT_DETAILS_FAIL = 'LATEST_PRODUCT_DETAILS_FAIL'

export const ADMIN_PRODUCT_DETAILS_REQUEST = 'ADMIN_PRODUCT_DETAILS_REQUEST'
export const ADMIN_PRODUCT_DETAILS_SUCCESS = 'ADMIN_PRODUCT_DETAILS_SUCCESS'
export const ADMIN_PRODUCT_DETAILS_FAIL = 'ADMIN_PRODUCT_DETAILS_FAIL'

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_RESET = 'DELETE_IMAGE_RESET'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST'
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_RESET = 'UPDATE_IMAGE_RESET'
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL'

export const ADMIN_PRODUCT_XLS_DETAILS_REQUEST = 'ADMIN_PRODUCT_XLS_DETAILS_REQUEST'
export const ADMIN_PRODUCT_XLS_DETAILS_SUCCESS = 'ADMIN_PRODUCT_XLS_DETAILS_SUCCESS'
export const ADMIN_PRODUCT_XLS_DETAILS_FAIL = 'ADMIN_PRODUCT_XLS_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
