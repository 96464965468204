import { lazy, Suspense, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { loadUser } from './actions/userActions'
import Loader from './components/layout/tools/loader/Loader'
import store from './store'
import { GoogleOAuthProvider } from '@react-oauth/google'

import Header from './components/layout/header/Header'
import Footer from './components/layout/Footer'
import PrivateRoute from './components/route/PrivateRoute'

import Home from './components/Home'
import Test from './components/Test'
import Details from './components/Details'
import Landing from './components/Landing'
import Auth from './components/user/Auth'
// User Imports 
const Page           = lazy(() => lazyRetry(() => import('./components/Page')))
const Profile        = lazy(() => lazyRetry(() => import('./components/user/Profile')))
const UpdateProfile  = lazy(() => lazyRetry(() => import('./components/user/UpdateProfile')))
const UpdatePassword = lazy(() => lazyRetry(() => import('./components/user/UpdatePassword')))
const ForgotPassword = lazy(() => lazyRetry(() => import('./components/user/ForgotPassword')))
const NewPassword    = lazy(() => lazyRetry(() => import('./components/user/NewPassword')))
// Admin Category Imports 
const Category       = lazy(() => lazyRetry(() => import('./components/admin/products/Category')))
const GeneralList    = lazy(() => lazyRetry(() => import('./components/admin/general/GeneralList')))
const NewGeneral     = lazy(() => lazyRetry(() => import('./components/admin/general/NewGeneral')))
const UpdateGeneral  = lazy(() => lazyRetry(() => import('./components/admin/general/UpdateGeneral')))
const NumericList    = lazy(() => lazyRetry(() => import('./components/admin/numeric/NumericList')))
const NewNumeric     = lazy(() => lazyRetry(() => import('./components/admin/numeric/NewNumeric')))
const UpdateNumeric  = lazy(() => lazyRetry(() => import('./components/admin/numeric/UpdateNumeric')))
const SocialList     = lazy(() => lazyRetry(() => import('./components/admin/social/SocialList')))
const NewSocial      = lazy(() => lazyRetry(() => import('./components/admin/social/NewSocial')))
const UpdateSocial   = lazy(() => lazyRetry(() => import('./components/admin/social/UpdateSocial')))
const HostingList    = lazy(() => lazyRetry(() => import('./components/admin/hosting/HostingList')))
const NewHosting     = lazy(() => lazyRetry(() => import('./components/admin/hosting/NewHosting')))
const UpdateHosting  = lazy(() => lazyRetry(() => import('./components/admin/hosting/UpdateHosting')))
const StatList       = lazy(() => lazyRetry(() => import('./components/admin/stats/StatList')))
const NewStat        = lazy(() => lazyRetry(() => import('./components/admin/stats/NewStat')))
const UpdateStat     = lazy(() => lazyRetry(() => import('./components/admin/stats/UpdateStat')))
const ExchangeList   = lazy(() => lazyRetry(() => import('./components/admin/exchanges/ExchangeList')))
const UpdateExchange = lazy(() => lazyRetry(() => import('./components/admin/exchanges/UpdateExchange')))
const NewExchange    = lazy(() => lazyRetry(() => import('./components/admin/exchanges/NewExchange')))

const StateList      = lazy(() => lazyRetry(() => import('./components/admin/states/StateList')))
const UpdateState    = lazy(() => lazyRetry(() => import('./components/admin/states/UpdateState')))
const NewState       = lazy(() => lazyRetry(() => import('./components/admin/states/NewState')))
// Admin Imports
const Dashboard      = lazy(() => lazyRetry(() => import('./components/admin/dashboard/Dashboard')))
const NewBanners     = lazy(() => lazyRetry(() => import('./components/admin/info/NewBanners')))
const UpdateBanners  = lazy(() => lazyRetry(() => import('./components/admin/info/UpdateBanners')))
// Admin User Imports
const UsersList      = lazy(() => lazyRetry(() => import('./components/admin/users/UsersList')))
const UpdateUser     = lazy(() => lazyRetry(() => import('./components/admin/users/UpdateUser')))
// Admin Product Imports
const ProductsList   = lazy(() => lazyRetry(() => import('./components/admin/products/ProductsList')))
const NewProduct     = lazy(() => lazyRetry(() => import('./components/admin/products/NewProduct')))
const UpdateProduct  = lazy(() => lazyRetry(() => import('./components/admin/products/UpdateProduct')))
// Admin Page Imports
const PageList       = lazy(() => lazyRetry(() => import('./components/admin/pages/PageList')))
const NewPage        = lazy(() => lazyRetry(() => import('./components/admin/pages/NewPage')))
const UpdatePage     = lazy(() => lazyRetry(() => import('./components/admin/pages/UpdatePage')))

const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      )
      componentImport().then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false') 
          resolve(component)
      }).catch((error) => {
          if (!hasRefreshed) { 
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true') 
              return window.location.reload() 
          }
          reject(error) 
      })
  })
}

function App() {  

  useEffect(() => {

    store.dispatch(loadUser())    
    
  }, [])  

  return (
    <>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    
    <Header />

    <main>

    <Suspense fallback={<Loader />}>

    <Routes>

      <Route path="/" element={<Landing />}  />
      <Route path="/:id" element={<PrivateRoute isAdmin={true}><Details /></PrivateRoute>} />
      <Route path="/home" element={<PrivateRoute isAdmin={true}><Home /></PrivateRoute>} />           
      <Route path="/test" element={<PrivateRoute isAdmin={true}><Test /></PrivateRoute>} />           
      <Route path="page/:slug" element={<Page />} />
      
      <Route path="/auth" element={<Auth />} />
      <Route path="/password/forgot" element={<ForgotPassword />} />
      <Route path="/password/reset/:token" element={<NewPassword />} /> 
      <Route path="/me" element={<PrivateRoute><Profile /></PrivateRoute>} />           
      <Route path="/me/update" element={<PrivateRoute><UpdateProfile /></PrivateRoute>} />
      <Route path="/password/update" element={<PrivateRoute><UpdatePassword /></PrivateRoute>} />

      <Route path="/admin/dashboard" element={<PrivateRoute isAdmin={true}><Dashboard /></PrivateRoute>} />
      <Route path="/admin/info" element={<PrivateRoute isAdmin={true}><NewBanners /></PrivateRoute>} />
      <Route path="/admin/banners" element={<PrivateRoute isAdmin={true}><UpdateBanners /></PrivateRoute>} />

      <Route path={`/admin/generals`} element={<PrivateRoute isAdmin={true}><GeneralList /></PrivateRoute>} />
      <Route path={`/admin/general/new`} element={<PrivateRoute isAdmin={true}><NewGeneral /></PrivateRoute>} />
      <Route path={`/admin/general/:id`} element={<PrivateRoute isAdmin={true}><UpdateGeneral /></PrivateRoute>} />

      <Route path={`/admin/numerics`} element={<PrivateRoute isAdmin={true}><NumericList /></PrivateRoute>} />
      <Route path={`/admin/numeric/new`} element={<PrivateRoute isAdmin={true}><NewNumeric /></PrivateRoute>} />
      <Route path={`/admin/numeric/:id`} element={<PrivateRoute isAdmin={true}><UpdateNumeric /></PrivateRoute>} />

      <Route path={`/admin/socials`} element={<PrivateRoute isAdmin={true}><SocialList /></PrivateRoute>} />
      <Route path={`/admin/social/new`} element={<PrivateRoute isAdmin={true}><NewSocial /></PrivateRoute>} />
      <Route path={`/admin/social/:id`} element={<PrivateRoute isAdmin={true}><UpdateSocial /></PrivateRoute>} />

      <Route path={`/admin/hostings`} element={<PrivateRoute isAdmin={true}><HostingList /></PrivateRoute>} />
      <Route path={`/admin/hosting/new`} element={<PrivateRoute isAdmin={true}><NewHosting /></PrivateRoute>} />
      <Route path={`/admin/hosting/:id`} element={<PrivateRoute isAdmin={true}><UpdateHosting /></PrivateRoute>} />

      <Route path={`/admin/stats`} element={<PrivateRoute isAdmin={true}><StatList /></PrivateRoute>} />
      <Route path={`/admin/stat/new`} element={<PrivateRoute isAdmin={true}><NewStat /></PrivateRoute>} />
      <Route path={`/admin/stat/:id`} element={<PrivateRoute isAdmin={true}><UpdateStat /></PrivateRoute>} />

      <Route path={`/admin/exchanges`} element={<PrivateRoute isAdmin={true}><ExchangeList /></PrivateRoute>} />
      <Route path={`/admin/exchange/new`} element={<PrivateRoute isAdmin={true}><NewExchange /></PrivateRoute>} />
      <Route path={`/admin/exchange/:id`} element={<PrivateRoute isAdmin={true}><UpdateExchange /></PrivateRoute>} />

      <Route path={`/admin/states`} element={<PrivateRoute isAdmin={true}><StateList /></PrivateRoute>} />
      <Route path={`/admin/state/new`} element={<PrivateRoute isAdmin={true}><NewState /></PrivateRoute>} />
      <Route path={`/admin/state/:id`} element={<PrivateRoute isAdmin={true}><UpdateState /></PrivateRoute>} />

      <Route path="/admin/products" element={<PrivateRoute isAdmin={true}><ProductsList /></PrivateRoute>} />                
      <Route path="/admin/category/:category/:id" element={<PrivateRoute isAdmin={true}><Category /></PrivateRoute>} />                
      <Route path="/admin/product" element={<PrivateRoute isAdmin={true}><NewProduct /></PrivateRoute>} />
      <Route path="/admin/product/:id" element={<PrivateRoute isAdmin={true}><UpdateProduct /></PrivateRoute>} />
    
      <Route path="/admin/users" element={<PrivateRoute isAdmin={true}><UsersList /></PrivateRoute>} />
      <Route path="/admin/user/:id" element={<PrivateRoute isAdmin={true}><UpdateUser /></PrivateRoute>} />
    
      <Route path="/admin/pages" element={<PrivateRoute isAdmin={true}><PageList /></PrivateRoute>} /> 
      <Route path="/admin/page" element={<PrivateRoute isAdmin={true}><NewPage /></PrivateRoute>} />               
      <Route path="/admin/page/:id" element={<PrivateRoute isAdmin={true}><UpdatePage /></PrivateRoute>} />
    </Routes>    

    </Suspense>

    </main>

    <Footer />

    </GoogleOAuthProvider>
    </>
  )
  
}

export default App
