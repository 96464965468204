import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPages } from '../../actions/pageActions'
import ScrollToTop from 'react-scroll-to-top'
import ArrowCircleUpIcon from './icons/arrows/ArrowCircleUpIcon'
import GavelIcon from './icons/navigation/GavelIcon'

const Footer = () => {  

  const dispatch = useDispatch()

  const { pages } = useSelector( state => state.pages )

  useEffect(() => {

    dispatch(getPages())

  }, [dispatch])

  return (
    <>
    <footer 
      className="background-panel text-center"
      style={{ borderTop: 'solid 1px var(--border-color)', padding: 10 }}
    >

      <div className="d-flex justify-content-between container wrapper">

        {pages && pages.map((page, index) => (
          
            <Link 
              to={`/page/${page.slug}`}
              key={index}
              className="d-flex gap-2 align-items-center"
            >
              <GavelIcon /><small>{page.title}</small>
            </Link>
        ))}

      </div>

      <small>Copyright &copy; <strong style={{ color: 'var(--icon-color)' }}>{process.env.REACT_APP_SITE_NAME}</strong> {new Date().getFullYear()}. All Rights Reserved.</small>
    
    </footer>

    <ScrollToTop 
      smooth 
      top={500}
      style={{ background: 'none', boxShadow: 'none', right: 0, bottom: '50%', transform: 'translateY(50%)' }} 
      component={<ArrowCircleUpIcon style={{ fontSize: '2.5rem' }} />} 
    />
    </>
  )

}

export default Footer