import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { pagesReducer, pageDetailsReducer, adminPageDetailsReducer, newPageReducer, pageReducer } from './reducers/pageReducers'
import { productXlsReducer, productsReducer, productsByCategoryReducer, updateProductStatsReducer, updateProductsReducer, productDetailsReducer, adminProductDetailsReducer, newProductReducer, productReducer } from './reducers/productReducers'
import { authReducer, userReducer, forgotPasswordReducer, allUsersdReducer, userDetailsReducer } from './reducers/userReducers'
import { newHomepageReducer, homepageReducer, homepageDetailsReducer } from './reducers/homepageReducers'
import { 
    numericsReducer, 
    newNumericReducer, 
    numericReducer,
    numericDetailsReducer,   
    generalsReducer, 
    newGeneralReducer, 
    generalReducer,
    generalDetailsReducer,    
    socialsReducer, 
    newSocialReducer, 
    socialReducer,
    socialDetailsReducer,    
    hostingsReducer, 
    newHostingReducer, 
    hostingReducer,
    hostingDetailsReducer,    
    statsReducer, 
    newStatReducer, 
    statReducer,
    statDetailsReducer,
    exchangesReducer, 
    newExchangeReducer, 
    exchangeReducer,
    exchangeDetailsReducer,
    statesReducer, 
    newStateReducer, 
    stateReducer,
    stateDetailsReducer    
} from './reducers/categoryReducers'
const reducer = combineReducers({    

    auth: authReducer,
    user: userReducer,
    allUsers: allUsersdReducer,
    userDetails: userDetailsReducer,
    forgotPassword: forgotPasswordReducer,   

    socials: socialsReducer,
    newSocial: newSocialReducer,
    social: socialReducer,
    socialDetails: socialDetailsReducer,

    generals: generalsReducer,
    newGeneral: newGeneralReducer,
    general: generalReducer,
    generalDetails: generalDetailsReducer,

    numerics: numericsReducer,
    newNumeric: newNumericReducer,
    numeric: numericReducer,
    numericDetails: numericDetailsReducer,

    hostings: hostingsReducer,
    newHosting: newHostingReducer,
    hosting: hostingReducer,
    hostingDetails: hostingDetailsReducer,

    stats: statsReducer,
    newStat: newStatReducer,
    stat: statReducer,
    statDetails: statDetailsReducer,   

    exchanges: exchangesReducer,
    newExchange: newExchangeReducer,
    exchange: exchangeReducer,
    exchangeDetails: exchangeDetailsReducer,   

    states: statesReducer,
    newState: newStateReducer,
    state: stateReducer,
    stateDetails: stateDetailsReducer,   

    products: productsReducer,   
    catProducts: productsByCategoryReducer,
    productDetails: productDetailsReducer,
    adminProductDetails: adminProductDetailsReducer,    
    newProduct: newProductReducer,
    product: productReducer,  
    updatedProducts: updateProductsReducer,
    updatedProductstats: updateProductStatsReducer,
    productXlsDetails: productXlsReducer,

    pages: pagesReducer, 
    page: pageReducer,   
    pageDetails: pageDetailsReducer,
    adminPageDetails: adminPageDetailsReducer,    
    newPage: newPageReducer,   

    homepage: homepageReducer,   
    homepageDetails: homepageDetailsReducer,
    newHomepage: newHomepageReducer,      
})

const middleware = [thunk]
const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)))

export default store