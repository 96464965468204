import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminProducts } from '../actions/productActions'
import MetaData from './layout/MetaData'
import Table from './layout/tables/Table'
import Loader from './layout/tools/loader/Loader'

const Test = () => {

    const dispatch = useDispatch()

    const { loading, products } = useSelector(state => state.products)

    useEffect(() => {
        dispatch(getAdminProducts())
    }, [dispatch])  

    const setData = () => {
        const data = {
            columns: [
                {
                    label: 'Ticker',
                    field: 'ticker',
                },   
                {
                    label: 'Name',
                    field: 'name',
                },  
                {
                    label: 'Price',
                    field: 'price',
                    sort: 'disabled',
                },          
                {
                    label: 'Vol',
                    field: 'vol',
                    sort: 'disabled',
                },  
                {
                    label: 'Cap',
                    field: 'cap',
                    sort: 'disabled',
                },             
            ],
            rows: []
        }

        products.forEach( p => {
            data.rows.push({
                ticker: p.ticker,                 
                name: p.name,
                price: p.xlsFeed[0]?.value?.toFixed(8),
                vol: p.xlsFeed[2]?.value,
                cap: p.xlsFeed[3]?.value                
            })
        })

        return data
    }
    
    return (
        <>
        <MetaData title="Test Page" />

        {loading ? <Loader /> : (
            <>  
            <div className="container">

                <div className="wrapper">   

                    <h1>Test Page</h1>  

                    {products && <Table setData={setData}  />}                    
                   
                </div>

            </div>
            </>
        )}
        </>
    )

}

export default Test