import { useState } from 'react'
import ArrowDropUpIcon from '../icons/arrows/ArrowDropUpIcon'
import ArrowDropDownIcon from '../icons/arrows/ArrowDropDownIcon'
import KeyboardArrowLeftIcon from '../icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon from '../icons/arrows/KeyboardArrowRightIcon'
import KeyboardDoubleArrowLeftIcon from '../icons/arrows/KeyboardDoubleArrowLeftIcon'
import KeyboardDoubleArrowRightIcon from '../icons/arrows/KeyboardDoubleArrowRightIcon'
import Input from '../forms/Input'
import './table.css'

const Table = ({ setData }) => {

    const initialData = setData()

    const [ currentPage,       setCurrentPage       ] = useState(1)
    const [ resultsPerPage,    setResultsPerPage    ] = useState(25)
    const [ filteredData,      setFilteredData      ] = useState(initialData.rows)
    const [ sortConfig,        setSortConfig        ] = useState(null)
    const [ searchTerm,        setSearchTerm        ] = useState('')
    const [ highlightedColumn, setHighlightedColumn ] = useState(null)

    const handleSort = (columnName) => {    
        let direction = 'desc'
        if (sortConfig && sortConfig.column === columnName && sortConfig.direction === 'desc') {
        direction = 'asc'
        }
        setSortConfig({ column: columnName, direction })
    }

    const sortedData = () => {
        if (!sortConfig) {
        return filteredData
        }

        const { column, direction } = sortConfig

        return [...filteredData].sort((a, b) => {
        
            if (a[column] < b[column]) {
                return direction === 'asc' ? -1 : 1
            }
            if (a[column] > b[column]) {
                return direction === 'asc' ? 1 : -1
            }
            return 0
        
        })       

    }

    const handleResultsPerPageChange = (event) => {
        const newResultsPerPage = parseInt(event.target.value)
        setResultsPerPage(newResultsPerPage)
        setCurrentPage(1)
    }

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= Math.ceil(sortedData().length / resultsPerPage)) {
        setCurrentPage(newPage)
        }
    } 

    const totalPages = Math.ceil(sortedData().length / resultsPerPage)

    const currentResults = sortedData().slice(
        (currentPage - 1) * resultsPerPage,
        currentPage * resultsPerPage
    ) 

    const searchData = (searchTerm) => {  

        const filteredResults = initialData.rows.filter((item) => {  
          return (
              Object.values(item).some((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
              ) 
          )
        })
    
        setFilteredData(filteredResults)
        setCurrentPage(1)
    }
    
    const handleSearchChange = (e) => {
        setSearchTerm( e.target.value)
        searchData( e.target.value)
    }

    const handleMouseEnter = (columnIndex) => {    
      setHighlightedColumn(columnIndex)
    }
  
    const handleMouseLeave = () => {
      setHighlightedColumn(null)
    }

    return (
      <>  
      <div className="data-table-container">  

          <Input 
              placeholder="Search..." 
              value={searchTerm} 
              onChange={handleSearchChange}             
          />   

          {filteredData.length > 0 ? (
              <>       
              <div className="data-table-wrapper" style={{ marginTop: 20 }}>
                  <table className="data-table">
                  <thead>
                    <tr>
                      {initialData.columns.map((column, columnIndex) => (
                        <th
                          key={columnIndex}
                          onClick={() => {
                            if (column.sort !== 'disabled') {
                              handleSort(column.field)
                            }
                          }}
                          onMouseEnter={() => handleMouseEnter(columnIndex)}
                          onMouseLeave={handleMouseLeave}
                          style={{ cursor: column.sort === 'disabled' ? 'default' : 'pointer' }}
                        >
                          <div className="d-flex align-items-center">
                              {column.label}{' '}
                              {column.sort !== 'disabled' &&
                              sortConfig?.column === column.field &&
                              (sortConfig.direction === 'asc' ? (
                                  <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} />
                              ) : (
                                  <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />
                              ))}
                            </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentResults.map((row, rowIndex) => (
                      <tr 
                        key={rowIndex} 
                        className="table-row"
                      >
                        {initialData.columns.map((column, columnIndex) => (
                          <td 
                            key={columnIndex}
                            className={highlightedColumn === columnIndex ? 'highlighted-column' : ''}
                          >
                            {column.field === 'url' ? (
                              row.url
                            ) : column.field === 'actions' ? (
                              row.actions
                            ) : (
                              row[column.field]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  </table>
              </div>

              <div 
                  className="d-flex justify-content-end align-items-center" 
                  style={{ gap: 20, marginTop: 20 }}
              >

              <small>Rows per page</small>

              <select 
                  value={resultsPerPage} 
                  onChange={handleResultsPerPageChange}
                  style={{ 
                      border: 'solid 1px var(--border-color)', 
                      background: 'var(--background)',
                      color: 'var(--foreground)',
                      outline: 'none'
                  }}
              >
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={400}>All</option>
              </select>

              <small>{currentPage} / {totalPages}</small>

              <div className="d-flex">

                  <button className="icon-button" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                      <KeyboardDoubleArrowLeftIcon/>
                  </button>
                  &nbsp;
                  <button className="icon-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                      <KeyboardArrowLeftIcon/>
                  </button>        
                  &nbsp;
                  <button className="icon-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                      <KeyboardArrowRightIcon/>
                  </button>
                  &nbsp;
                  <button className="icon-button" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                      <KeyboardDoubleArrowRightIcon/>
                  </button>

              </div>

              </div>
              </>
          ) : (

              <p>No results.</p>

          )}
        
      </div>
      </>
    )
}

export default Table
