import { useEffect                } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrors, getProductDetails } from '../actions/productActions'
import { useAlert } from 'react-alert'
import { Link, useParams } from 'react-router-dom'
import MetaData from './layout/MetaData'
import Loader from './layout/tools/loader/Loader'
import ArrowBackIcon from './layout/icons/arrows/ArrowBackIcon'

const Details = () => {  
    
    const dispatch  = useDispatch() 
    const alert     = useAlert()   
    const productId = useParams().id 

    const { loading, product, error } = useSelector( state => state.productDetails )
 
    useEffect(() => {

        dispatch(getProductDetails(productId))
        
        if(error) {            
            alert.error(error)  
            dispatch(clearErrors())          
        } 
    }, [dispatch, alert, error, productId])       

    return (
        <>
        <MetaData title={`${product.name}`} />       

        {loading ? <Loader /> : (

            <div className="container">

                <div className="background-panel wrapper">

                    <h1 className="d-flex align-items-center gap-2">
                        <Link to="/">
                            <button className="icon-button">
                                <ArrowBackIcon />
                            </button>                                     
                        </Link> 
                        <img src={product.icon && product.icon.url} alt={product.name} />
                        Details for {product.name}
                    </h1>

                </div>   

            </div>  
        )}
        </>        
    )  
}

export default Details