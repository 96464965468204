export const ALL_GENERALS_REQUEST = 'ALL_GENERALS_REQUEST'
export const ALL_GENERALS_SUCCESS = 'ALL_GENERALS_SUCCESS'
export const ALL_GENERALS_FAIL = 'ALL_GENERALS_FAIL'

export const GENERAL_DETAILS_REQUEST = 'GENERAL_DETAILS_REQUEST'
export const GENERAL_DETAILS_SUCCESS = 'GENERAL_DETAILS_SUCCESS'
export const GENERAL_DETAILS_FAIL = 'GENERAL_DETAILS_FAIL'

export const NEW_GENERAL_REQUEST = 'NEW_GENERAL_REQUEST'
export const NEW_GENERAL_SUCCESS = 'NEW_GENERAL_SUCCESS'
export const NEW_GENERAL_RESET = 'NEW_GENERAL_RESET'
export const NEW_GENERAL_FAIL = 'NEW_GENERAL_FAIL'

export const UPDATE_GENERAL_REQUEST = 'UPDATE_GENERAL_REQUEST'
export const UPDATE_GENERAL_SUCCESS = 'UPDATE_GENERAL_SUCCESS'
export const UPDATE_GENERAL_RESET = 'UPDATE_GENERAL_RESET'
export const UPDATE_GENERAL_FAIL = 'UPDATE_GENERAL_FAIL'

export const DELETE_GENERAL_REQUEST = 'DELETE_GENERAL_REQUEST'
export const DELETE_GENERAL_SUCCESS = 'DELETE_GENERAL_SUCCESS'
export const DELETE_GENERAL_RESET = 'DELETE_GENERAL_RESET'
export const DELETE_GENERAL_FAIL = 'DELETE_GENERAL_FAIL'

export const ALL_NUMERICS_REQUEST = 'ALL_NUMERICS_REQUEST'
export const ALL_NUMERICS_SUCCESS = 'ALL_NUMERICS_SUCCESS'
export const ALL_NUMERICS_FAIL = 'ALL_NUMERICS_FAIL'

export const NUMERIC_DETAILS_REQUEST = 'NUMERIC_DETAILS_REQUEST'
export const NUMERIC_DETAILS_SUCCESS = 'NUMERIC_DETAILS_SUCCESS'
export const NUMERIC_DETAILS_FAIL = 'NUMERIC_DETAILS_FAIL'

export const NEW_NUMERIC_REQUEST = 'NEW_NUMERIC_REQUEST'
export const NEW_NUMERIC_SUCCESS = 'NEW_NUMERIC_SUCCESS'
export const NEW_NUMERIC_RESET = 'NEW_NUMERIC_RESET'
export const NEW_NUMERIC_FAIL = 'NEW_NUMERIC_FAIL'

export const UPDATE_NUMERIC_REQUEST = 'UPDATE_NUMERIC_REQUEST'
export const UPDATE_NUMERIC_SUCCESS = 'UPDATE_NUMERIC_SUCCESS'
export const UPDATE_NUMERIC_RESET = 'UPDATE_NUMERIC_RESET'
export const UPDATE_NUMERIC_FAIL = 'UPDATE_NUMERIC_FAIL'

export const DELETE_NUMERIC_REQUEST = 'DELETE_NUMERIC_REQUEST'
export const DELETE_NUMERIC_SUCCESS = 'DELETE_NUMERIC_SUCCESS'
export const DELETE_NUMERIC_RESET = 'DELETE_NUMERIC_RESET'
export const DELETE_NUMERIC_FAIL = 'DELETE_NUMERIC_FAIL'

export const ALL_SOCIALS_REQUEST = 'ALL_SOCIALS_REQUEST'
export const ALL_SOCIALS_SUCCESS = 'ALL_SOCIALS_SUCCESS'
export const ALL_SOCIALS_FAIL = 'ALL_SOCIALS_FAIL'

export const SOCIAL_DETAILS_REQUEST = 'SOCIAL_DETAILS_REQUEST'
export const SOCIAL_DETAILS_SUCCESS = 'SOCIAL_DETAILS_SUCCESS'
export const SOCIAL_DETAILS_FAIL = 'SOCIAL_DETAILS_FAIL'

export const NEW_SOCIAL_REQUEST = 'NEW_SOCIAL_REQUEST'
export const NEW_SOCIAL_SUCCESS = 'NEW_SOCIAL_SUCCESS'
export const NEW_SOCIAL_RESET = 'NEW_SOCIAL_RESET'
export const NEW_SOCIAL_FAIL = 'NEW_SOCIAL_FAIL'

export const UPDATE_SOCIAL_REQUEST = 'UPDATE_SOCIAL_REQUEST'
export const UPDATE_SOCIAL_SUCCESS = 'UPDATE_SOCIAL_SUCCESS'
export const UPDATE_SOCIAL_RESET = 'UPDATE_SOCIAL_RESET'
export const UPDATE_SOCIAL_FAIL = 'UPDATE_SOCIAL_FAIL'

export const DELETE_SOCIAL_REQUEST = 'DELETE_SOCIAL_REQUEST'
export const DELETE_SOCIAL_SUCCESS = 'DELETE_SOCIAL_SUCCESS'
export const DELETE_SOCIAL_RESET = 'DELETE_SOCIAL_RESET'
export const DELETE_SOCIAL_FAIL = 'DELETE_SOCIAL_FAIL'

export const ALL_HOSTINGS_REQUEST = 'ALL_HOSTINGS_REQUEST'
export const ALL_HOSTINGS_SUCCESS = 'ALL_HOSTINGS_SUCCESS'
export const ALL_HOSTINGS_FAIL = 'ALL_HOSTINGS_FAIL'

export const HOSTING_DETAILS_REQUEST = 'HOSTING_DETAILS_REQUEST'
export const HOSTING_DETAILS_SUCCESS = 'HOSTING_DETAILS_SUCCESS'
export const HOSTING_DETAILS_FAIL = 'HOSTING_DETAILS_FAIL'

export const NEW_HOSTING_REQUEST = 'NEW_HOSTING_REQUEST'
export const NEW_HOSTING_SUCCESS = 'NEW_HOSTING_SUCCESS'
export const NEW_HOSTING_RESET = 'NEW_HOSTING_RESET'
export const NEW_HOSTING_FAIL = 'NEW_HOSTING_FAIL'

export const UPDATE_HOSTING_REQUEST = 'UPDATE_HOSTING_REQUEST'
export const UPDATE_HOSTING_SUCCESS = 'UPDATE_HOSTING_SUCCESS'
export const UPDATE_HOSTING_RESET = 'UPDATE_HOSTING_RESET'
export const UPDATE_HOSTING_FAIL = 'UPDATE_HOSTING_FAIL'

export const DELETE_HOSTING_REQUEST = 'DELETE_HOSTING_REQUEST'
export const DELETE_HOSTING_SUCCESS = 'DELETE_HOSTING_SUCCESS'
export const DELETE_HOSTING_RESET = 'DELETE_HOSTING_RESET'
export const DELETE_HOSTING_FAIL = 'DELETE_HOSTING_FAIL'

export const ALL_STATS_REQUEST = 'ALL_STATS_REQUEST'
export const ALL_STATS_SUCCESS = 'ALL_STATS_SUCCESS'
export const ALL_STATS_FAIL = 'ALL_STATS_FAIL'

export const STAT_DETAILS_REQUEST = 'STAT_DETAILS_REQUEST'
export const STAT_DETAILS_SUCCESS = 'STAT_DETAILS_SUCCESS'
export const STAT_DETAILS_FAIL = 'STAT_DETAILS_FAIL'

export const NEW_STAT_REQUEST = 'NEW_STAT_REQUEST'
export const NEW_STAT_SUCCESS = 'NEW_STAT_SUCCESS'
export const NEW_STAT_RESET = 'NEW_STAT_RESET'
export const NEW_STAT_FAIL = 'NEW_STAT_FAIL'

export const UPDATE_STAT_REQUEST = 'UPDATE_STAT_REQUEST'
export const UPDATE_STAT_SUCCESS = 'UPDATE_STAT_SUCCESS'
export const UPDATE_STAT_RESET = 'UPDATE_STAT_RESET'
export const UPDATE_STAT_FAIL = 'UPDATE_STAT_FAIL'

export const DELETE_STAT_REQUEST = 'DELETE_STAT_REQUEST'
export const DELETE_STAT_SUCCESS = 'DELETE_STAT_SUCCESS'
export const DELETE_STAT_RESET = 'DELETE_STAT_RESET'
export const DELETE_STAT_FAIL = 'DELETE_STAT_FAIL'

export const ALL_EXCHANGES_REQUEST = 'ALL_EXCHANGES_REQUEST'
export const ALL_EXCHANGES_SUCCESS = 'ALL_EXCHANGES_SUCCESS'
export const ALL_EXCHANGES_FAIL = 'ALL_EXCHANGES_FAIL'

export const EXCHANGE_DETAILS_REQUEST = 'EXCHANGE_DETAILS_REQUEST'
export const EXCHANGE_DETAILS_SUCCESS = 'EXCHANGE_DETAILS_SUCCESS'
export const EXCHANGE_DETAILS_FAIL = 'EXCHANGE_DETAILS_FAIL'

export const NEW_EXCHANGE_REQUEST = 'NEW_EXCHANGE_REQUEST'
export const NEW_EXCHANGE_SUCCESS = 'NEW_EXCHANGE_SUCCESS'
export const NEW_EXCHANGE_RESET = 'NEW_EXCHANGE_RESET'
export const NEW_EXCHANGE_FAIL = 'NEW_EXCHANGE_FAIL'

export const UPDATE_EXCHANGE_REQUEST = 'UPDATE_EXCHANGE_REQUEST'
export const UPDATE_EXCHANGE_SUCCESS = 'UPDATE_EXCHANGE_SUCCESS'
export const UPDATE_EXCHANGE_RESET = 'UPDATE_EXCHANGE_RESET'
export const UPDATE_EXCHANGE_FAIL = 'UPDATE_EXCHANGE_FAIL'

export const DELETE_EXCHANGE_REQUEST = 'DELETE_EXCHANGE_REQUEST'
export const DELETE_EXCHANGE_SUCCESS = 'DELETE_EXCHANGE_SUCCESS'
export const DELETE_EXCHANGE_RESET = 'DELETE_EXCHANGE_RESET'
export const DELETE_EXCHANGE_FAIL = 'DELETE_EXCHANGE_FAIL'

export const ALL_STATES_REQUEST = 'ALL_STATES_REQUEST'
export const ALL_STATES_SUCCESS = 'ALL_STATES_SUCCESS'
export const ALL_STATES_FAIL = 'ALL_STATES_FAIL'

export const STATE_DETAILS_REQUEST = 'STATE_DETAILS_REQUEST'
export const STATE_DETAILS_SUCCESS = 'STATE_DETAILS_SUCCESS'
export const STATE_DETAILS_FAIL = 'STATE_DETAILS_FAIL'

export const NEW_STATE_REQUEST = 'NEW_STATE_REQUEST'
export const NEW_STATE_SUCCESS = 'NEW_STATE_SUCCESS'
export const NEW_STATE_RESET = 'NEW_STATE_RESET'
export const NEW_STATE_FAIL = 'NEW_STATE_FAIL'

export const UPDATE_STATE_REQUEST = 'UPDATE_STATE_REQUEST'
export const UPDATE_STATE_SUCCESS = 'UPDATE_STATE_SUCCESS'
export const UPDATE_STATE_RESET = 'UPDATE_STATE_RESET'
export const UPDATE_STATE_FAIL = 'UPDATE_STATE_FAIL'

export const DELETE_STATE_REQUEST = 'DELETE_STATE_REQUEST'
export const DELETE_STATE_SUCCESS = 'DELETE_STATE_SUCCESS'
export const DELETE_STATE_RESET = 'DELETE_STATE_RESET'
export const DELETE_STATE_FAIL = 'DELETE_STATE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'