const ToggleOnIcon = ({ style }) => {

    return (

        <svg style={style} className="icon" viewBox="0 0 24 24">
            <path d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
        </svg>
        
    )
}

export default ToggleOnIcon