import { useSpring, animated } from 'react-spring'
import ReactDOM from 'react-dom'
import ToolTip from '../../layout/tools/tooltip/ToolTip'
import ClearIcon from '../../layout/icons/actions/ClearIcon'
import Backdrop from '../../layout/tools/Backdrop'
import './modal.css'

function Modal({ isModalVisible, onBackdropClick, content }) {

    const animation = useSpring({  
      maxWidth:   isModalVisible ? '480px' : '0px',        
      opacity:    isModalVisible ? 1 : 0,
      transform:  isModalVisible ? 'translateX(0)' : 'translateX(100%)'
    })    

    if(!isModalVisible)  return null

    return ReactDOM.createPortal( 
      <>
      <Backdrop onclick={onBackdropClick} />

      <animated.div className="modal" style={animation}>  

        <div className="scroll-y screenheight">        
          {content}
        </div>
        <span style={{ position: 'absolute', top: 5, right: 5 }}>
          <ToolTip title="Close">
              <button className="icon-button"
                  onClick={onBackdropClick}
                >
                  <ClearIcon />
              </button>
          </ToolTip>
        </span>
        
      </animated.div>
      </>,
      document.getElementById('modal-root')    
    )
}

export default Modal
