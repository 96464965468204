import GitHubIcon from '../../layout/icons/social/GitHubIcon'


const GithubLoginButton = ({ setLoginLoader, subscriber, isSignUp }) => {

    const githubLogin = () => {
        setLoginLoader(true)
        window.location.href = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=user:email&state=${subscriber}`
    }

    return (

        <button 
            type="button"
            className="button github"             
            onClick={githubLogin}
        >
            <GitHubIcon style={{ color: 'white' }} />
            Sign {isSignUp ? 'up' : 'in'} with GitHub
        </button>  

    )

}

export default GithubLoginButton