import axios from 'axios'
import { 
    ADMIN_PRODUCTS_REQUEST,
    ADMIN_PRODUCTS_SUCCESS,
    ADMIN_PRODUCTS_FAIL,
    CATEGORY_PRODUCTS_REQUEST,
    CATEGORY_PRODUCTS_SUCCESS,
    CATEGORY_PRODUCTS_FAIL,
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCTS_REQUEST,
    UPDATE_PRODUCTS_SUCCESS,
    UPDATE_PRODUCTS_FAIL,
    UPDATE_PRODUCT_STATS_REQUEST,
    UPDATE_PRODUCT_STATS_SUCCESS,
    UPDATE_PRODUCT_STATS_FAIL,
    ALL_PRODUCTS_REQUEST, 
    ALL_PRODUCTS_SUCCESS, 
    ALL_PRODUCTS_FAIL,    
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    ADMIN_PRODUCT_DETAILS_REQUEST,
    ADMIN_PRODUCT_DETAILS_SUCCESS,
    ADMIN_PRODUCT_DETAILS_FAIL,   
    ADMIN_PRODUCT_XLS_DETAILS_REQUEST,
    ADMIN_PRODUCT_XLS_DETAILS_SUCCESS,
    ADMIN_PRODUCT_XLS_DETAILS_FAIL, 
    CLEAR_ERRORS 
} from '../constants/productConstants'

// Action creator to initiate finding a record
export const findRecordByName = (name) => async (dispatch) => {
    dispatch({ type: ADMIN_PRODUCT_XLS_DETAILS_REQUEST })

    try {
        // Make API call or perform any asynchronous operation here
        const response = await fetch(`/api/v1/admin/product/${name}`) // Adjust the API endpoint as needed
        const data = await response.json()

        dispatch({
            type: ADMIN_PRODUCT_XLS_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.error('Error finding record:', error)
        dispatch({
            type: ADMIN_PRODUCT_XLS_DETAILS_FAIL,
            payload: { error: 'Failed to find record' }
        })
    }
}

// Get all products
export const getProducts = () => async (dispatch) => {
    try {

        dispatch({ type: ALL_PRODUCTS_REQUEST })         

        const { data } = await axios.get('/api/v1/products')

        dispatch({
            type: ALL_PRODUCTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get products - (Admin)
export const getAdminProducts = () => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_PRODUCTS_REQUEST })

        const { data } = await axios.get('/api/v1/admin/products')

        dispatch({
            type: ADMIN_PRODUCTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: ADMIN_PRODUCTS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get products by category - (Admin)
export const getAdminProductsByCategory = (category, id) => async (dispatch) => {    
    try {

        dispatch({ type: CATEGORY_PRODUCTS_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/category/${category}/${id}`)

        dispatch({
            type: CATEGORY_PRODUCTS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: CATEGORY_PRODUCTS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get Single Product Details
export const getProductDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: PRODUCT_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/v1/product/${id}`)

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data.product
        })
        
    } catch (error) {

        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message
        })

    }
}

// Get Single Product Details Admin
export const getAdminProductDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: ADMIN_PRODUCT_DETAILS_REQUEST })        

        const { data } = await axios.get(`/api/v1/admin/product/${id}`)

        dispatch({
            type: ADMIN_PRODUCT_DETAILS_SUCCESS,
            payload: data.product
        })
        
    } catch (error) {

        dispatch({
            type: ADMIN_PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message
        })

    }
}

// New Product (Admin)
export const newProduct = (productData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_PRODUCT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'                
            }
        }

        const { data } = await axios.post('/api/v1/admin/product/new', productData, config)

        dispatch({
            type: NEW_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update Product (Admin)
export const updateProduct = (id, productData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCT_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'   
            }
        }
        const { data } = await axios.put(`/api/v1/admin/product/${id}`, productData, config)
        dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update Products (Admin)
export const updateProducts = (category, productsData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCTS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'   
            }
        }
        const { data } = await axios.put(`/api/v1/admin/products/${category}`, productsData, config)
        dispatch({
            type: UPDATE_PRODUCTS_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCTS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update Product Stats from XLS (Admin)
export const updateProductStats = (productsData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCT_STATS_REQUEST })
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'   
            }
        }
        const { data } = await axios.put(`/api/v1/admin/productStats`, productsData, config)
        dispatch({
            type: UPDATE_PRODUCT_STATS_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_STATS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete product (Admin)
export const deleteProduct = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_PRODUCT_REQUEST })      

        const { data } = await axios.delete(`/api/v1/admin/product/${id}`)

        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: data.success
        })
        
    } catch (error) {

        dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: error.response.data.message
        })

    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}