const KeyboardArrowRightIcon = () => {

    return (

        <svg className="icon" viewBox="0 0 24 24">
            <path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
        </svg>

    )

}

export default KeyboardArrowRightIcon