import { useEffect                } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts              } from '../actions/productActions'
import { formatMyDateTime         } from '../Utils'
import MetaData                     from './layout/MetaData'
import Loader                       from './layout/tools/loader/Loader'
import DataTable                    from './layout/tables/DataTable'

const Home = () => {   

    const dispatch = useDispatch()    

    const { loading, products } = useSelector(state => state.products)

    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch])       

    return (
        <>
        <MetaData title={'eYnodes Network'} />       

        {loading ? <Loader /> : (

            <div className="grid">

                <div className="grid-sidebar">
                    <h4>Left Widgets</h4>                    
                </div>

                <div className="grid-main">

                    <div className="background-panel wrapper">

                        <p className="text-right">
                            
                            {products && products[0] && products[0].updatedAt && (
                                <small>
                                    <img src="/images/default-avatar.png" alt="EyNodes" width="10" />                                
                                    &nbsp;                                
                                    {products.length} node{products.length !== 1 ? 's' : ''}
                                    &nbsp;
                                    <b>updated:</b> {formatMyDateTime(products[0].updatedAt)}
                                </small>
                            )}

                        </p> 

                        {products && <DataTable data={products} />}                        

                    </div>   

                </div>  

                <div className="grid-sidebar">
                    <h4>Right Widgets</h4>
                </div>

            </div>
        )}
        </>        
    )  
}

export default Home