import {    
    NEW_PAGE_REQUEST,
    NEW_PAGE_RESET,
    NEW_PAGE_SUCCESS,
    NEW_PAGE_FAIL,
    UPDATE_PAGE_REQUEST,
    UPDATE_PAGE_SUCCESS,
    UPDATE_PAGE_RESET,
    UPDATE_PAGE_FAIL,
    DELETE_PAGE_REQUEST,
    DELETE_PAGE_SUCCESS,
    DELETE_PAGE_RESET,
    DELETE_PAGE_FAIL,   
    PAGE_DETAILS_REQUEST,
    PAGE_DETAILS_SUCCESS,
    PAGE_DETAILS_FAIL,  
    CLEAR_ERRORS 
} from '../constants/homepageConstants'

export const newHomepageReducer = ( state = { homepage: {} }, action ) => {
    switch (action.type) {

        case NEW_PAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_PAGE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                homepage: action.payload.homepage
            }
        case NEW_PAGE_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_PAGE_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const homepageReducer = ( state = {}, action ) => {
    switch (action.type) { 
        case DELETE_PAGE_REQUEST:
        case UPDATE_PAGE_REQUEST:
            return {
                ...state,
                loading: true
            } 
        case DELETE_PAGE_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }  
        case UPDATE_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }   
        case DELETE_PAGE_FAIL:
        case UPDATE_PAGE_FAIL:
            return {
                ...state,
                error: action.payload
            }        
        case DELETE_PAGE_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_PAGE_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const homepageDetailsReducer = ( state = { homepage: {} }, action ) => {
    switch (action.type) {
        case PAGE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PAGE_DETAILS_SUCCESS:
            return {
                loading: false,
                homepage: action.payload
            }
        case PAGE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
