import DoneIcon from '../icons/actions/DoneIcon'
import './form.css'

const Checkbox = ({ checked, onChange, label }) => {

    return (

        <div className="d-flex gap-2 align-items-center">

            <label className="checkbox">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <DoneIcon style={{ opacity: checked ? 1 : 0 }} />
                
            </label>

            <small>{label}</small>

        </div>
    )

}

export default Checkbox