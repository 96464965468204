const CategoryIcon = () => {

    return (

        <svg className="icon" viewBox="0 0 24 24">
            <path d="m12 2-5.5 9h11z" />
            <circle cx="17.5" cy="17.5" r="4.5" />
            <path d="M3 13.5h8v8H3z" />
        </svg>

    )

}

export default CategoryIcon