import { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import RemoveIcon from '../icons/actions/RemoveIcon'
import LinkButton from '../tools/LinkButton'

const HiddenRow = ({ product }) => {   

    const [ sColls, setSColls ] = useState([])        
    const [ eColls, setEColls ] = useState([])        
    const [ vColls, setVColls ] = useState([])        
    const [ mColls, setMColls ] = useState([])        
    const [ kColls, setKColls ] = useState([]) 
    const [ rewardVisible, setRewardVisible ] = useState(false)   

    useEffect(() => {

        if (product.sColls) {
            const array = product.sColls.split(';')      
            const output = array.map((a, i) => (
                <li className="input" key={i}>{a}</li>
            ))           
            setSColls(output)
        }
        if (product.eColls) {
            const array = product.eColls.split(';')            
            const output = array.map((a, i) => (
                <li className="input" key={i}>{a}</li>
            ))           
            setEColls(output)
        }
        if (product.vColls) {
            const array = product.vColls.split(';')            
            const output = array.map((a, i) => (
                <li className="input" key={i}>{a}</li>
            ))           
            setVColls(output)
        }
        if (product.mColls) {
            const array = product.mColls.split(';')            
            const output = array.map((a, i) => (
                <li className="input" key={i}>{a}</li>
            ))           
            setMColls(output)
        }
        if (product.kColls) {
            const array = product.kColls.split(';')            
            const output = array.map((a, i) => (
                <li className="input" key={i}>{a}</li>
            ))           
            setKColls(output)
        }        
        
    }, [product.sColls, product.eColls, product.vColls, product.mColls, product.kColls])         
 
    const checkValue = (array, name) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].name === name) {
                if (array[i].value) {
                    return array[i].value
                }
            }
        }
    }

    const appear = useSpring({
        transform: rewardVisible ? "translateY(0)" : "translateY(-40px)",
        opacity: rewardVisible ? 1 : 0
    }) 

    return (
        <>
        <td colSpan="10" style={{ padding: 20, borderBottom: 'solid 1px var(--border-color)' }}>

            <table>
                <tbody>
                    <tr>

                        <td style={{ padding: 20 }}>

                            <h3><img src={product.icon.url} alt={product.name} width="32"/> {product.name}</h3>                            

                        </td>

                        <td style={{ padding: 20 }}>

                        <table>
                            <tbody>
                            <tr>
                                <td style={{ paddingBottom: 20 }}><b>Social</b></td>
                            </tr>
                            <tr>
                                <td>                                     
                                    <LinkButton array={product.social} category="Website" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.social} category="Bitcointalk" />
                                </td>
                            </tr>

                            <tr>
                                <td>                                    
                                    <LinkButton array={product.social} category="Discord" />
                                    
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.social} category="Facebook" />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        </td>

                        <td style={{ padding: 20 }}>

                        <table>
                            <tbody>
                            <tr>
                                <td style={{ paddingBottom: 20 }}><b>General</b></td>
                            </tr>
                            <tr>
                                <td> 
                                    <LinkButton array={product.general} category="API Url" />
                                </td>
                            </tr>

                            <tr>
                                <td>                
                                    <LinkButton array={product.general} category="Balance string" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.general} category="Block string" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.general} category="Explorer 1" />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        </td>

                        <td style={{ padding: 20 }}>

                        <table>
                            <tbody>
                            <tr>
                                <td style={{ paddingBottom: 20 }}><b>Hosting</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <LinkButton array={product.hosting} category="Allnodes" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.hosting} category="Crypos" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.hosting} category="DeltaApp" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <LinkButton array={product.hosting} category="Flits" />
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        </td>

                        <td style={{ padding: 20 }}>

                        <table style={{ background: 'var(--background)' }}>
                            <tbody>
                            <tr>
                                <td style={{ paddingBottom: 20 }}><b>Miscellaneous</b></td>
                            </tr>
                            <tr>
                                <td>Block time:</td>
                                <td>
                                    {checkValue(product.numeric, 'Block time') ? (
                                        checkValue(product.numeric, 'Block time')
                                    ) : (
                                        <RemoveIcon style={{ color: 'var(--cta-red)' }} />   
                                    )}
                                </td>
                            </tr>

                            <tr>
                                <td>MN port:</td>
                                <td>
                                    {checkValue(product.numeric, 'MN port') ? (
                                        checkValue(product.numeric, 'MN port')
                                    ) : (
                                        <RemoveIcon style={{ color: 'var(--cta-red)' }} /> 
                                    )}
                                </td>
                            </tr>

                            <tr>
                                <td>Max supply</td>
                                <td>
                                    {checkValue(product.numeric, 'Max supply') ? (
                                        checkValue(product.numeric, 'Max supply')
                                    ) : (
                                        <RemoveIcon style={{ color: 'var(--cta-red)' }} />
                                    )}
                                </td>
                            </tr>

                            <tr>
                                <td>ERPC port</td>
                                <td>
                                    {checkValue(product.numeric, 'ERPC port') ? (
                                        checkValue(product.numeric, 'ERPC port')
                                    ) : (
                                        <RemoveIcon style={{ color: 'var(--cta-red)' }} />   
                                    )}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        </td>
                    </tr>
                </tbody>
            </table>

            <button 
                className="button"
                onClick={() => setRewardVisible(!rewardVisible)}
                style={{ padding: '10px 20px', width: 'auto' }}
            >
                Reward Table
            </button>

            {rewardVisible && (            
                <animated.div style={appear}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h2 style={{ margin: '20px 0px 40px 0px' }}>Columns <small style={{ color: 'var(--primary-color)' }}>({product.nColls})</small></h2>

                                    <div className="background-panel wrapper parent colls gap-2">

                                        <ul>
                                            <li>s Colls</li>
                                            {sColls}
                                        </ul>   

                                        <ul>
                                            <li>e Colls</li>
                                            {eColls}
                                        </ul>   

                                        <ul>
                                            <li>v Colls</li>
                                            {vColls}
                                        </ul>  

                                        <ul>
                                            <li>m Colls</li>
                                            {mColls}
                                        </ul>  

                                        <ul>
                                            <li>k Colls</li>
                                            {kColls}
                                        </ul>  

                                    </div>   

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </animated.div>

            )}

        </td>




       
        </>
    )

}

export default HiddenRow