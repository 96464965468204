import { 
    ADMIN_PRODUCTS_REQUEST,
    ADMIN_PRODUCTS_SUCCESS,
    ADMIN_PRODUCTS_FAIL,
    CATEGORY_PRODUCTS_REQUEST,
    CATEGORY_PRODUCTS_SUCCESS,
    CATEGORY_PRODUCTS_FAIL,
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_RESET,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_RESET,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCTS_REQUEST,
    UPDATE_PRODUCTS_SUCCESS,
    UPDATE_PRODUCTS_RESET,
    UPDATE_PRODUCTS_FAIL,
    UPDATE_PRODUCT_STATS_REQUEST,
    UPDATE_PRODUCT_STATS_SUCCESS,
    UPDATE_PRODUCT_STATS_RESET,
    UPDATE_PRODUCT_STATS_FAIL,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_RESET,
    DELETE_PRODUCT_FAIL,
    ALL_PRODUCTS_REQUEST, 
    ALL_PRODUCTS_SUCCESS, 
    ALL_PRODUCTS_FAIL,    
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    ADMIN_PRODUCT_DETAILS_REQUEST,
    ADMIN_PRODUCT_DETAILS_SUCCESS,
    ADMIN_PRODUCT_DETAILS_FAIL,
    ADMIN_PRODUCT_XLS_DETAILS_REQUEST,
    ADMIN_PRODUCT_XLS_DETAILS_SUCCESS,
    ADMIN_PRODUCT_XLS_DETAILS_FAIL,
    CLEAR_ERRORS 
} from '../constants/productConstants'

export const productXlsReducer = ( state = {}, action ) => {
    switch(action.type) {

        case ADMIN_PRODUCT_XLS_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }  
        
        case ADMIN_PRODUCT_XLS_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload
            }

        case ADMIN_PRODUCT_XLS_DETAILS_FAIL:
             return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const productsReducer = ( state = { products: [] }, action ) => {
    switch(action.type) {

        case ALL_PRODUCTS_REQUEST:
        case ADMIN_PRODUCTS_REQUEST:
            return {
                loading: true,
                products: [],
            }
        
        case ALL_PRODUCTS_SUCCESS:
            return {
                loading: false,
                products: action.payload.products,
                productsCount: action.payload.productsCount,
                resPerPage: action.payload.resPerPage,
                filteredProductsCount: action.payload.filteredProductsCount
            }        
        
        case ADMIN_PRODUCTS_SUCCESS:
            return {
                loading: false,
                productsCount: action.payload.productsCount,
                products: action.payload.products
            }        
       
        case ALL_PRODUCTS_FAIL:
        case ADMIN_PRODUCTS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}

export const productsByCategoryReducer = ( state = { catProducts: [] }, action ) => {
    switch(action.type) {

        case CATEGORY_PRODUCTS_REQUEST:
            return {
                loading: true,
                catProducts: [],
            }  
        
        case CATEGORY_PRODUCTS_SUCCESS:
            return {
                loading: false,
                catProducts: action.payload.products
            }

        case CATEGORY_PRODUCTS_FAIL:
             return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                 error: null
            }

        default:
            return state

    }
}


export const newProductReducer = ( state = { product: {} }, action ) => {
    switch (action.type) {

        case NEW_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_PRODUCT_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                product: action.payload.product
            }
        case NEW_PRODUCT_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_PRODUCT_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const productReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }   
        case DELETE_PRODUCT_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }   
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }     
        
        case DELETE_PRODUCT_FAIL:
        case UPDATE_PRODUCT_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        case DELETE_PRODUCT_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_PRODUCT_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const updateProductsReducer = ( state = {}, action ) => {
    switch (action.type) {

        case UPDATE_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true
            }   
        
        case UPDATE_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                areUpdated: action.payload
            }     
        
        case UPDATE_PRODUCTS_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        
        case UPDATE_PRODUCTS_RESET:
            return {
                ...state,
                areUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const updateProductStatsReducer = ( state = {}, action ) => {
    switch (action.type) {

        case UPDATE_PRODUCT_STATS_REQUEST:
            return {
                ...state,
                loading: true
            }   
        
        case UPDATE_PRODUCT_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                areUpdated: action.payload
            }     
        
        case UPDATE_PRODUCT_STATS_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        
        case UPDATE_PRODUCT_STATS_RESET:
            return {
                ...state,
                areUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const productDetailsReducer = ( state = { product: {} }, action ) => {
    switch (action.type) {

        case PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload
            }
        case PRODUCT_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const adminProductDetailsReducer = ( state = { product: {} }, action ) => {
    switch (action.type) {

        case ADMIN_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADMIN_PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload
            }
        case ADMIN_PRODUCT_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}


